import React from 'react';
import { connect } from 'react-redux';
import Plotly from 'plotly.js-dist-min';
import createPlotlyComponent from 'react-plotly.js/factory';
import moment from 'moment';
import Loader from 'components/Loader';
import { SHOW_NOTIFICATION } from 'reducers/notification/constants';
import axios from 'axios';
import { Refresh } from '@material-ui/icons';

const Plot = createPlotlyComponent(Plotly);
class MonitoringObjectTrendChart extends React.Component {
    constructor(props) {
        super(props);

        this.newGetTrendData = this.newGetTrendData.bind(this);

        this.state = {
            loading: false,
            data: {
                x: [],
                y: []
            },
            fftDataRevision: 0,
            optimumWidth: window.innerWidth * 95 / 100
        }
    }

    componentDidMount() {
        this.getTrendData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.device !== this.props.device ||
            prevProps.sensor !== this.props.sensor ||
            prevProps.monitoringObject !== this.props.monitoringObject ||
            prevProps.startTime !== this.props.startTime ||
            prevProps.endTime !== this.props.endTime ||
            prevProps.authentication.kustoToken !== this.props.authentication.kustoToken
        ) {
            if (this.props.authentication.kustoToken) {
                if (!this.state.loading) {
                    this.getTrendData();
                }
            }
        }
    }

    async newGetTrendData() {
        

        var dateSize = new Array();
        var currentDate = moment(this.props.startTime);

        while (currentDate < this.props.endTime) {

            dateSize.push(moment(currentDate))
            currentDate = currentDate.add(7, 'days');

        }
        dateSize.push(this.props.endTime)
        dateSize = dateSize.reverse();

        
        if (this.props.authentication.kustoToken) {
            this.setState({
                data: {
                    x: [],
                    y: []
                },
                loading: true
            })

            for (var i = 0; i < dateSize.length; i++) {
                if (dateSize[i + 1]) {
                    await axios
                        .post('api/trend/' + this.props.device + '/' + this.props.sensor + '/' + this.props.monitoringObject,
                            {
                                'startTime': dateSize[i + 1],
                                'endTime': dateSize[i],
                            },
                            {
                                headers: {
                                    'Authorization': 'Bearer ' + this.props.authentication.kustoToken
                                },
                                timeout: 0
                            })
                        .then(res => {

                            var timestampsArray = res.data.timestamps
                            if (timestampsArray) {
                                for (let i = 0; i < timestampsArray.length; i++) {
                                    timestampsArray[i] = moment(timestampsArray[i]).format('YYYY-MM-DDTHH:mm:ss');
                                }
                            }

                            this.setState({
                                data: {
                                    x: [...this.state.data.x, ...timestampsArray],
                                    y: [...this.state.data.y, ...res.data.values]
                                },
                                fftDataRevision: this.state.fftDataRevision + 1, 
                                loading: false
                            })
                        })
                        .finally(() => {
                            this.setState({
                                loading: false
                            })
                        })


                }

            }

        }


    }


    getTrendData = async () => {
        this.newGetTrendData()

        // if (this.props.authentication.kustoToken) {
        //     this.setState({            
        //         data: {
        //             x: [],
        //             y: []
        //         },
        //         loading: true
        //     })

        //     await axios.post('api/trend/' + this.props.device + '/' + this.props.sensor + '/' + this.props.monitoringObject,
        //         {
        //             'startTime': this.props.startTime,
        //             'endTime': this.props.endTime,
        //         },
        //         {
        //             headers: {
        //                 'Authorization': 'Bearer ' + this.props.authentication.kustoToken
        //             },
        //             timeout: 0
        //         })
        //         .then(res => {

        //             // Convert from UTC to local
        //             var timestampsArray = res.data.timestamps
        //             if(timestampsArray){
        //                 for(let i = 0; i < timestampsArray.length;i++){
        //                     timestampsArray[i] = moment(timestampsArray[i]).format('YYYY-MM-DDTHH:mm:ss');
        //                 }
        //             }

        //             this.setState({
        //                 data: {
        //                     x: timestampsArray,
        //                     y: res.data.values
        //                 },
        //                 fftDataRevision: this.state.fftDataRevision + 1,
        //                 loading: false
        //             })

        //         })
        //         .catch(error => {
        //             this.props.showNotification(error.message, 'danger');
        //             this.setState({
        //                 fftDataRevision: this.state.fftDataRevision + 1,
        //                 loading: false
        //             })
        //         })
        //         .finally(() => {
        //             this.setState({
        //                 loading: false
        //             })
        //         });
        // }
    }


    getTrendLayout = () => {
        var warning = 0
        var alarm = 0

        var deviceThresholds = this.props.global.devices?.[this.props.site]?.[this.props.device]?.thresholds
        for (var i in deviceThresholds) {
            if (deviceThresholds[i].sensor.name === this.props.sensor) {

                if (deviceThresholds[i].monitoringObj.name === this.props.monitoringObject) {
                    warning = deviceThresholds[i].warning
                    alarm = deviceThresholds[i].alarm
                    break;
                }
            }
        }





        return {
            datarevision: this.state.fftDataRevision,
            uirevision: this.state.fftDataRevision,
            width: this.state.optimumWidth,
            height: 450,
            xaxis: {
                type: 'date',
                title: 'Timestamp',
                color: '#005591',
                range: [
                    moment(this.props.startTime).format('YYYY-MM-DDTHH:mm:ss'),
                    moment(this.props.endTime).format('YYYY-MM-DDTHH:mm:ss'),
                ]
            },
            yaxis: {
                type: 'string',
                title: 'mm/s',
                color: '#005591'
            },
            shapes: [{
                name: 'alarm',
                type: 'line',
                xref: 'paper',
                x0: 0,
                y0: alarm,
                x1: 1,
                y1: alarm,
                line: {
                    color: '#e1000f',
                    width: 2,
                    dash: 'dot'
                }
            },
            {
                name: 'warning',
                type: 'line',
                xref: 'paper',
                x0: 0,
                y0: warning,
                x1: 1,
                y1: warning,
                line: {
                    color: '#DC7800',
                    width: 3,
                    dash: 'dot'
                }
            }]
        };
    };

    render() {
        return <>
            {
                (this.state.loading) ? <Loader title='Generating Data' position='inherit' /> : <>
                    <Refresh style={{float: 'right'}} onClick={this.newGetTrendData}/>
                    <Plot
                        data={[this.state.data]}
                        layout={this.getTrendLayout()}
                    />
                </>
            }
        </>
    }

}


let mapStateToProps = (state) => state;

let mapDispatchToProps = dispatch => {
    return {
        showNotification: function (msg, intent) {
            dispatch({
                type: SHOW_NOTIFICATION,
                message: msg,
                intent: intent
            });
        }
    };
};



// MonitoringObjectTable.propTypes = {
//     site: PropTypes.string,
//     device: PropTypes.string,
//     sensor: PropTypes.string
// };


export default connect(mapStateToProps, mapDispatchToProps)(MonitoringObjectTrendChart);
