
import { useState, useEffect } from 'react';
import {
    Button, Grid, Paper,
    TableContainer, Table, TableCell, TableHead, TableRow, TableBody, TablePagination, TableFooter,
    Select, FormControl
} from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import moment from 'moment-timezone';

const AlarmPanel = props => {

    /* ----------------------------------------------------------------------------------- */
    // #region Alarm Panel
    /* ----------------------------------------------------------------------------------- */

    const [alarmItem, setAlarmItem] = useState('ALL');
    const [alarmPage, setAlarmPage] = useState(0);
    const [filteredAlarm, setFilteredAlarm] = useState([]);
    const [displayAlarmItem, setDisplayAlarmItem] = useState([]);
    const [alarmLastRefresh, setAlarmLastRefresh] = useState(moment().format('DD MMM YYY hh:mm:ss A'));



    useEffect(() => {
        setAlarmItem('ALL')
        setAlarmPage(0);
        setFilteredAlarm(props.alarmData);
    }, [props.alarmData])

    useEffect(() => {

        if (alarmItem !== 'ALL') {
            setFilteredAlarm(props.alarmData.filter(x => x.item2.toUpperCase() === alarmItem));
        }
        else {
            setFilteredAlarm(props.alarmData);
        }
        setAlarmPage(0);
    }, [alarmItem])

    useEffect(() => {
        setDisplayAlarmItem(filteredAlarm.slice((alarmPage * 20) + 0, (alarmPage * 20) + 20))
    }, [alarmPage])

    useEffect(() => {
        setDisplayAlarmItem(filteredAlarm.slice((alarmPage * 20) + 0, (alarmPage * 20) + 20))
    }, [filteredAlarm])

    const handleAlarmChangePage = (event, newPage) => {
        setAlarmPage(newPage);
    };

    const getAlarmItems = () => {
        let sensorTypes = [];

        for (let i = 0; i < props.alarmData.length; i++) {
            sensorTypes.push(props.alarmData[i].item2);
        }


        const uniqueString = [...new Set(sensorTypes)];
        let tempReturn = [<option key={'ALL'} value={'ALL'}>All</option>];

        for (let i = 0; i < uniqueString.length; i++) {


            tempReturn.push(<option key={uniqueString[i]} value={uniqueString[i].toUpperCase()}>{uniqueString[i]}</option>);
        }

        return tempReturn;
    };

    const onSelectAlarmItem = (event) => {
        setAlarmItem(event.toUpperCase());


    };

    const refreshAlarmPanel = () => {
        console.log(props)
        setAlarmLastRefresh(moment().format('DD MMM YYY hh:mm:ss A'));
        props.getAlarms();
    };

    //#endregion
    /* ----------------------------------------------------------------------------------- */

    return <>
        <Grid container justifyContent='center' className={'center-mt80'}>
            <Grid item>
                <Grid container justifyContent='space-between' style={{ textAlign: 'center' }}>
                    <Grid item>
                        <h1 className={'header1'}>Alarm Panel</h1>
                    </Grid>
                    <Grid item>
                        <FormControl>
                            {/* <InputLabel style={{ color: '7FAAC8' }} htmlFor='age-native-simple'>Age</InputLabel> */}
                            <Select
                                id='linde-dropdown'
                                style={{ width: '150px', border: '1px solid #B9CDD7', background: 'white', boxShadow: 'inset 2px 2px 6px #B9CDD7', color: '#7FAAC8' }}
                                native
                                value={alarmItem}
                                onChange={(item) => onSelectAlarmItem(item.target.value)}
                                inputProps={{
                                    name: 'age',
                                    id: 'age-native-simple',
                                }}
                                disabled={(props.alarmData.length > 0) ? false : true}
                            >
                                {getAlarmItems()}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <Paper className={'paper'} style={{ width: '1200px' }} elevation={0}>
                    <TableContainer className={'table-container'}>
                        <Table>
                            {(props.alarmData.length > 0) ? null : <caption id={'empty-row'}>Data is empty</caption>}
                            <TableHead>
                                <TableRow>
                                    <TableCell id={'table-header'} align='center'>No</TableCell>
                                    <TableCell id={'table-header'} align='center'>Timestamp</TableCell>
                                    <TableCell id={'table-header'} align='center'>Sensor</TableCell>
                                    <TableCell id={'table-header'} align='center'>Monitoring Object</TableCell>
                                    <TableCell id={'table-header'} align='center'>Before Alarm</TableCell>
                                    <TableCell id={'table-header'} align='center'>After Alarm</TableCell>
                                    <TableCell id={'table-header'} align='center'>Threshold</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {displayAlarmItem.map((row, index) => (
                                    <TableRow className={(index % 2) ? 'even-row' : 'odd-row'} key={index}>
                                        <TableCell align='center'>{++index + (alarmPage * 20)}</TableCell>
                                        <TableCell align='center'>{row.item1.format('DD MMM YYYY hh:mm A')}</TableCell>
                                        <TableCell align='center'>{row.item2}</TableCell>
                                        <TableCell align='center'>{row.item3}</TableCell>
                                        <TableCell align='center'>{row.item4}</TableCell>
                                        <TableCell style={{ backgroundColor: '#ff000040' }} align='center'><span style={{ color: '#c00000' }}>{row.item5}</span></TableCell>
                                        <TableCell align='center'>{row.item6}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>

                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        count={filteredAlarm.length}
                                        page={alarmPage}
                                        onPageChange={handleAlarmChangePage}
                                        rowsPerPage={20}
                                        rowsPerPageOptions={[20]}
                                    /></TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Paper>

                <br />

                <Button id='LindeButton' startIcon={<Refresh />} onClick={refreshAlarmPanel}>
                    <span className='button-text'>Last Refresh: {alarmLastRefresh}</span>
                </Button>
            </Grid>
        </Grid>
    </>

}
export default (AlarmPanel)