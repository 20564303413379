import { COMPLETED, UPDATE_DEVICES, UPDATE_MONITOROBJ, 
    UPDATE_MONITOROBJALL,CHANGE_QUERY_STATUS, 
    UPDATE_SITES } from './constants';

const initialState = {
    sites: null,
    isDefault: true,
    devices: null,
    completed: false,
    isQuerying: true

};

const globalReducer = (state = initialState, action) => {
    switch (action.type) {

        case UPDATE_SITES:
            return {
                ...state,
                sites : action.payload
            };
        case UPDATE_DEVICES:
            return {
                ...state,
                devices: action.payload
            };

        case CHANGE_QUERY_STATUS:
            return {
                ...state,
                isQuerying: !state.isQuerying
            }

        case COMPLETED: 
        return {
            ...state, 
            completed: true
        }

        // case "UPDATE_CONNECTIVITY": 
        // return {
        //     ...state,
        //     sites: {
        //         ...state.sites,
        //         [action.site]: {
        //             ...state.sites[action.site],
        //             [action.edge]: {
        //                 ...state.sites[action.site][action.edge],  
        //                 "connectionState": action.connectionState,
        //                 "lastActivityTime": action.lastActivityTime
        //             }
        //         }
        //     }
        // };

        
        case UPDATE_MONITOROBJ:
            return {
                ...state,
                devices: {
                    ...state.devices,
                    [action.site]: {
                        ...state.devices[action.site],
                        [action.edge]: {
                            ...state.devices[action.site][action.edge],
                            "sensors": {
                                ...state.devices[action.site][action.edge]["sensors"],
                                [action.sensor]: {
                                    ...state.devices[action.site][action.edge]["sensors"][action.sensor],
                                    [action.monitoringobj]: {
                                        ...state.devices[action.site][action.edge]["sensors"][action.sensor][action.monitoringobj],
                                        "status": action.monitoringobjstatus,
                                        "value": action.monitoringobjVal
                                    }
                                }
                            }
                        }
                    }
                }
            };


        case UPDATE_MONITOROBJALL: 
        return {
            ...state,
            devices: action.payload,
        };


        // case "UPDATE_MONITOROBJS":
        //     return {
        //         ...state,
        //         sites: {
        //             ...state.sites,
        //             [action.site]: {
        //                 ...state.sites[action.site],
        //                 [action.edge]: {
        //                     ...state.sites[action.site][action.edge],
        //                     "sensors": {
        //                         ...state.sites[action.site][action.edge]["sensors"],
        //                         [action.sensor]: action.sensorData
        //                     }
        //                 }
        //             }
        //         }
        //     };

        // case "UPDATE_ALLMONITOROBJ":
        //     return {
        //         ...state,
        //         sites: {
        //             ...state.sites,
        //             [action.site]: {
        //                 ...state.sites[action.site],
        //                 [action.edge]: {
        //                     ...state.sites[action.site][action.edge],
        //                     "sensors": action.sensorData
        //                 }
        //             }
        //         }
        //     };


        // case "UPDATE_DEVICE_ALARM":
        //     return {
        //         ...state,
        //         devices: {
        //             ...state.devices,
        //             [action.device]: action.status
        //         }
        //     }


        default:
            return {
                ...state
            };

    }

}

export default globalReducer;