import React from 'react';
import { Error } from '@material-ui/icons';
import { Button } from '@material-ui/core';

/**
 * Loader animation for async requests
 */

const Loader = (props) => {

    const { title, position, isError, cancelToken } = props;

    const sectionStyle = {
        position: (position) ? position : 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 10,
    };
    
    const wrapperStyle = {
        width: '100%',
        height: '100%',
        textAlign: 'center',
        background: '#d2e1ea',
        opacity: 0.85,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
    };

    const titleStyle = {
        fontSize: '0.8em',
        color: '#005591'
    };

    const abortMission = () => {
        console.log('Triggered Abort');
        cancelToken.cancel('Abort Manual');
    };

    return (
        <section style={sectionStyle}>
            <div style={wrapperStyle}>
                {
                    (isError) ? 
                        (<Error fontSize={'large'} style={{color: '#005591'}} />)
                    :
                        (
                            <svg
                                width='38'
                                height='38'
                                viewBox='0 0 38 38'
                                xmlns='http://www.w3.org/2000/svg'
                                stroke='rgb(0, 85, 145)'
                            >
                                <g fill='none' fillRule='evenodd'>
                                    <g transform='translate(1 1)' strokeWidth='2'>
                                        <circle strokeOpacity='.5' cx='18' cy='18' r='18' />
                                        <path d='M36 18c0-9.94-8.06-18-18-18'>
                                            <animateTransform
                                                attributeName='transform'
                                                type='rotate'
                                                from='0 18 18'
                                                to='360 18 18'
                                                dur='1s'
                                                repeatCount='indefinite'
                                            />
                                        </path>
                                    </g>
                                </g>
                            </svg>
                        )
                }
                <br />
                <p style={titleStyle}>
                    {
                        title ? 
                            (cancelToken) ?
                                <div>
                                    {title}
                                    {
                                        (cancelToken.token.reason) ?
                                            <></> : 
                                            <div><br /><Button onClick={abortMission} style={{ color: 'red' }}>Abort</Button></div>
                                    }
                                </div>
                                : title
                            : 'Loading'
                    }
                </p>
            </div>
        </section>
    );
};

export default Loader;