import { UPDATE_ACCOUNT, UPDATE_EDGE_CENTRAL, UPDATE_ERROR, UPDATE_GRAPH, UPDATE_KUSTO, UPDATE_TOKEN } from './constants';

export const updateAccount = (payload) => ({
    type: UPDATE_ACCOUNT,
    payload,
});

export const updateError = (payload) => ({
    type: UPDATE_ERROR,
    payload,
});

export const updateToken = (payload) => ({
    type: UPDATE_TOKEN,
    payload,
});

export const updateKusto = (payload) => ({
    type: UPDATE_KUSTO,
    payload,
});

export const updateGraphToken = (payload) => ({
    type: UPDATE_GRAPH,
    payload,
});

export const updateEdgeCentralToken = (payload) => ({
    type: UPDATE_EDGE_CENTRAL,
    payload,
});
