import React, { useEffect, useState } from 'react';
import { IconButton, List, ListItem, Button, Drawer, ListItemText, Collapse, Divider, ListSubheader } from '@material-ui/core';
import { Menu, ExpandMore, Business, Home, LocationOn, ExpandLess, TrendingUp, DesktopWindows, Description } from '@material-ui/icons';
import Loader from 'components/Loader';
import { connect } from 'react-redux';
import axios from 'axios';


import { updateDevices, updateSites, updateMonitoringObj, completed } from 'reducers/global/actions'


const badges = ['Normal', 'Invalid', 'Warning', 'Alarm'];

const DrawerMenu = props => {

    const getNavigationList = () => {

        axios
            .get('api/ObjectCV/getNavigationList',
                {
                    headers: { 'Authorization': 'Bearer ' + props.authentication.kustoToken }
                }
            )
            .then(res => {
                props.completed();
                props.updateDevices(res.data);
            })
            .catch(function (error) {

                props.showNotification(error.message ?? error.response.data, 'danger')
                props.completed();
            });
    }


    useEffect(() => {

        if (props.global.devices === null && props.authentication.kustoToken !== null && !props.global.completed) {
            getNavigationList();
        }
    }, [props.authentication.kustoToken])




    useEffect(() => {
        var payload = props.global.devices;
        var nodeArray = [];
        var itemId = 0;

        for (var site in payload) {
            var newSite = newNode(itemId++, site, badges[0], []);
            var siteStatus = badges[0];

            for (var device in payload[site]) {
                // console.log('Device > ', payload[site][device].label);
                var newDevice = newNode(itemId++, payload[site][device].label, badges[0], []);
                var deviceStatus = badges[0];





                for (var sensor in Object.keys(payload[site][device].sensors).sort().reduce((r, k) => (r[k] = payload[site][device].sensors[k], r), {})) 
                {
                    var newSensor = newNode(itemId++, sensor, badges[0], []);
                    var sensorStatus = badges[0];

                    for (var momObj in payload[site][device].sensors[sensor]) {
                        var momStatus = badges[0];

                        var arrThreshold = payload[site][device].thresholds
                        var warning = 0;
                        var alarm = 0;
                        var sensorValue = payload[site][device].sensors[sensor][momObj].value;

                        for (var ind in arrThreshold) {
                            if (arrThreshold[ind].sensor.name === sensor &&
                                arrThreshold[ind].monitoringObj.name === momObj
                            ) {

                                warning = arrThreshold[ind].warning
                                alarm = arrThreshold[ind].alarm
                                break;
                            }
                        }



                        // Enhanced that Alarm and Warning cant be 0 if 0 and value is 0 then is normal
                        if (sensorValue >= alarm && alarm !== 0) {
                            momStatus = badges[3]
                        } else if (sensorValue >= warning && warning !== 0) {
                            momStatus = badges[2]
                        } else if (sensorValue >= 0) {
                            momStatus = badges[0];
                        }


                        sensorStatus = updateParentStatus(sensorStatus, momStatus);
                        newSensor.childNode.push(newNode(itemId++, momObj, defineStatusColor(momStatus), null, sensorValue, []));
                    }

                    deviceStatus = updateParentStatus(deviceStatus, sensorStatus);


                    newSensor.classColor = defineStatusColor(sensorStatus);
                    newDevice.childNode.push(newSensor);
                }

                siteStatus = updateParentStatus(siteStatus, deviceStatus);
                newDevice.classColor = defineStatusColor(deviceStatus);
                newSite.childNode.push(newDevice);
            }

            newSite.classColor = defineStatusColor(siteStatus);
            nodeArray.push(newSite);
        }

        setMenuItems(nodeArray);
        setTimeout(function () {
            setIsLoading(false);
        }, 2000);
    }, [props.global.devices]);

    const updateParentStatus = (oldStatus, newStatus) => {
        if (badges.findIndex(x => x === oldStatus) < badges.findIndex(x => x === newStatus)) {
            return newStatus;
        }

        return oldStatus;
    };

    const defineStatusColor = (status) => {
        var greenBadge = 'notification-badge notification-badge--green';
        var yellowBadge = 'notification-badge notification-badge--yellow';
        var redBadge = 'notification-badge notification-badge--red';
        var invalidBadge = 'notification-badge notification-badge--grey';

        switch (status) {
            case badges[0]:
                return greenBadge;

            case badges[1]:
                return invalidBadge;

            case badges[2]:
                return yellowBadge;

            case badges[3]:
                return redBadge;

            default:
                return invalidBadge;
        }
    };

    const newNode = (id, name, classColor, childNode, value) => {
        return {
            id: id,
            name: name,
            value: value,
            classColor: classColor,
            open: false,
            childNode: childNode
        };
    };

    const [currentPage, setCurrentPage] = useState(-1);
    const [isLoading, setIsLoading] = useState(true);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [menuItems, setMenuItems] = useState([]);

    const navigateToPage = (location, pageId) => {
        setOpenDrawer(!openDrawer);
        setCurrentPage(pageId);
        window.open('#/' + location, '_self');
    };

    const onListItemExpandClick = (itemId) => {
        // let tempMenuItems = [...menuItems].map(menuItem => ({ ...menuItem, open: false }) );
        let tempMenuItems = [...menuItems];

        for (var siteId in tempMenuItems) {

            tempMenuItems[siteId].open = (tempMenuItems[siteId].id === itemId) ? !tempMenuItems[siteId].open : false;

            for (var deviceId in tempMenuItems[siteId].childNode) {

                tempMenuItems[siteId].childNode[deviceId].open = (tempMenuItems[siteId].childNode[deviceId].id === itemId) ? !tempMenuItems[siteId].childNode[deviceId].open : false;

                if (tempMenuItems[siteId].childNode[deviceId].id === itemId) {
                    tempMenuItems[siteId].open = true;
                }

                for (var sensorId in tempMenuItems[siteId].childNode[deviceId].childNode) {

                    tempMenuItems[siteId].childNode[deviceId].childNode[sensorId].open = (tempMenuItems[siteId].childNode[deviceId].childNode[sensorId].id === itemId) ? !tempMenuItems[siteId].childNode[deviceId].childNode[sensorId].open : false;

                    if (tempMenuItems[siteId].childNode[deviceId].childNode[sensorId].id === itemId) {
                        tempMenuItems[siteId].open = true;
                        tempMenuItems[siteId].childNode[deviceId].open = true;
                    }
                }
            }
        }


        setMenuItems(tempMenuItems);
    };

    // Sub-Menu Level 4 : Spectrum
    const spectrum = (parentItems) => {
        return (
            <List>
                {
                    parentItems.map((item) => {

                        return (<React.Fragment key={`Spectrum-${item.id}`}>
                            <ListItem id={'drawer-list-item'} dense className='drawer-menu-item-level-4'>
                                <Button id={'drawer-button'} fullWidth disabled style={{ textTransform: 'none' }}>
                                    <TrendingUp />
                                    <ListItemText primary={`${item.name} - ${item.value ? item.value.toFixed(3) : 0} mm/s`} className='drawer-menu-item-text' style={{ textTransform: 'none' }} />
                                </Button>
                                <span className={item.classColor} style={{ marginRight: '44px' }}></span>
                            </ListItem>
                        </React.Fragment>);
                    })
                }
            </List>
        );
    };

    // Sub-Menu Level 3 : Sensors
    const sensor = (parentItems, siteName, deviceName) => {
        return (
            <List>
                {
                    parentItems.map((item) => {
                        return (
                            <React.Fragment key={`Sensor-${item.id}`}>
                                <ListItem id={'drawer-list-item'} className={currentPage === item.id ? 'is-active drawer-menu-item-level-3' : 'drawer-menu-item-level-3'}>
                                    <Button id={'drawer-button'} fullWidth onClick={() => { navigateToPage(`Overview/${siteName}/${deviceName}/${item.name}`, item.id); }}>
                                        <DesktopWindows />
                                        <ListItemText primary={item.name} className='drawer-menu-item-text' style={{ textTransform: 'none' }} />
                                    </Button>
                                    <span className={item.classColor}></span>
                                    <IconButton id={'drawer-button'} onClick={() => { onListItemExpandClick(item.id); }}>
                                        {(item.open) ? <ExpandLess /> : <ExpandMore />}
                                    </IconButton>
                                </ListItem>

                                <Collapse in={item.open} timeout="auto" unmountOnExit>
                                    {spectrum(item.childNode, item.name)}
                                </Collapse>
                            </React.Fragment>
                        );
                    })
                }
            </List>
        );
    };

    // Sub-Menu Level 2 : Devices
    const device = (parentItems, siteName) => {
        return (
            <List>
                {
                    parentItems.map((item) => {
                        return (
                            <React.Fragment key={`Device-${item.id}`}>
                                <ListItem id={'drawer-list-item'} className={currentPage === item.id ? 'is-active drawer-menu-item-level-2' : 'drawer-menu-item-level-2'}>
                                    <Button id={'drawer-button'} fullWidth onClick={() => { navigateToPage(`Overview/${siteName}/${item.name}`, item.id); }}>
                                        <Business />
                                        <ListItemText primary={item.name} className='drawer-menu-item-text' style={{ textTransform: 'none' }} />
                                    </Button>
                                    <span className={item.classColor}></span>
                                    <IconButton id={'drawer-button'} onClick={() => { onListItemExpandClick(item.id); }}>
                                        {(item.open) ? <ExpandLess /> : <ExpandMore />}
                                    </IconButton>
                                </ListItem>

                                <Collapse in={item.open} timeout="auto" unmountOnExit>
                                    {sensor(item.childNode, siteName, item.name)}
                                </Collapse>
                            </React.Fragment>
                        );
                    })
                }
            </List>
        );
    };

    // Sub-Menu Level 1 : Sites
    const site = () => {


        return (
            <List>
                <ListSubheader disableSticky={true} style={{ color: '#00A0E1' }}>Sites</ListSubheader>
                {
                    menuItems.sort((a, b) => a.name < b.name ? - 1 : Number(a.name > b.name)).map((item) => {
                        return (
                            <React.Fragment key={`site-${item.name}`}>
                                <ListItem id={'drawer-list-item'} className=''>
                                    <Button id={'drawer-button'} fullWidth onClick={() => { onListItemExpandClick(item.id); }}>
                                        <LocationOn />
                                        <ListItemText primary={item.name} className='drawer-menu-item-text' />
                                    </Button>
                                    <span className={item.classColor}></span>
                                    <IconButton id={'drawer-button'} onClick={() => { onListItemExpandClick(item.id); }}>
                                        {(item.open) ? <ExpandLess /> : <ExpandMore />}
                                    </IconButton>
                                </ListItem>

                                <Collapse in={item.open} timeout="auto" unmountOnExit>
                                    {device(item.childNode, item.name)}
                                </Collapse>
                            </React.Fragment>
                        );
                    })
                }
            </List>
        );
    };

    const list = () => {
        return (
            <List id='drawer-menu' subheader={<ListSubheader disableSticky={true} style={{ color: '#00A0E1' }}>General</ListSubheader>}>
                <ListItem id={'drawer-list-item'} className={currentPage === -1 ? 'is-active' : ''}>
                    <Button id={'drawer-button'} fullWidth onClick={() => { navigateToPage('', -1); }}>
                        <Home />
                        <ListItemText primary='Home' className='drawer-menu-item-text' />
                    </Button>
                </ListItem>

                <ListItem id={'drawer-list-item'} className={currentPage === -2 ? 'is-active' : ''}>
                    <Button id={'drawer-button'} fullWidth onClick={() => {
                        navigateToPage('Manual', -2);
                    }}>
                        <Description />
                        <ListItemText primary='Manual' className='drawer-menu-item-text' />
                    </Button>
                </ListItem>

                <Divider />

                {(isLoading) ? null : site()}

            </List>
        );
    };

    return (
        <>
            <IconButton
                aria-label='menu'
                color='inherit'
                edge='start'
                onClick={() => setOpenDrawer(true)}
                style={{ marginLeft: '1rem' }}
            >
                <Menu className='drawer-menu-icon' />
            </IconButton>
            <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
                {isLoading ? <Loader title='Getting assets' /> : null}
                {list()}
            </Drawer>
        </>
    );
};

let mapStateToProps = (state) => state;

let mapDispatchToProps = dispatch => {
    return {
        updateSites: (sites) => {
            dispatch(updateSites(sites))
        },
        updateDevices: (devices) => {
            dispatch(updateDevices(devices))
        },
        updateMonitoringObj: (site, edge, sensor, monitoringobj, monitoringobjstatus, monitoringobjVal) => {
            dispatch(updateMonitoringObj(site, edge, sensor, monitoringobj, monitoringobjstatus, monitoringobjVal))
        },
        completed: () => {
            dispatch(completed())
        },
        updateDeviceStatus: function (deviceName, devicestatus) {
            dispatch({
                type: "UPDATE_DEVICE_ALARM",
                device: deviceName,
                status: devicestatus
            });
        },
        showNotification: function (msg, intent) {
            dispatch({
                type: "SHOW_NOTIFICATION",
                message: msg,
                intent: intent
            });
        }
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(DrawerMenu);
