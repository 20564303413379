import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import Loader from 'components/Loader';
import MapView from 'components/Map';
import { useSelector } from 'react-redux';

const HomePage = props => {

    const accessToken = useSelector(state => state.authentication.accessToken);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        document.title = 'EDAS - Home';
    }, []);

    useEffect(() => {
        if (accessToken) {
            setIsLoading(false);
        }
    }, [accessToken]);

    

    return (
        <>
            {
                (isLoading) ? 
                    <Loader title="Getting devices" />
                :
                    <Grid container style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        borderRadius: '5'
                    }}>
                        <MapView />
                    </Grid>
            }
        </>
    );
};

export default HomePage;