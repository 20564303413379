import React from 'react';
import Plotly from 'plotly.js-dist-min';
import createPlotlyComponent from 'react-plotly.js/factory';

const Plot = createPlotlyComponent(Plotly);

const Spectrogram = props => {
    
    const { data, layout } = props;

    return (
        <Plot useResizeHandler 
            data={data}
            layout={layout}
        />
    );

};

export default Spectrogram;