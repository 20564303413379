import {
    UPDATE_NAVIGATION,
    SHOW_DATETIME_PICKER,
    UPDATE_DATETIME
} from './constants';

const initialState = {
    nodes: [],
    startDateTime: null,
    endDateTime: null,
    showDateTimePicker: false
};


const navigationReducer = (state = initialState, action) => {
    switch (action.type) {

        case UPDATE_NAVIGATION:
            return {
                ...state,
                nodes: action.data
            };
            
        case SHOW_DATETIME_PICKER:
            return {
                ...state,
                showDateTimePicker: action.data
            };
            
        case UPDATE_DATETIME:
            return {
                ...state,
                startDateTime: action.start,
                endDateTime: action.end
            };

        default:
            return state;
    }
};

export default navigationReducer;