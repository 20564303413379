import React, { useEffect, useState } from "react";
import { MapContainer, Marker, TileLayer, LayersControl, LayerGroup } from "react-leaflet";
import L from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { connect } from 'react-redux';
import "components/Map/styles.scss";

import Loader from 'components/Loader'
import TooltipTemplate from 'components/Map/TooltipTemplate'
import normalDevice from 'components/Map/devicePin/normal-device.svg'
import warningDevice from 'components/Map/devicePin/warning-device.svg'
import alarmDevice from 'components/Map/devicePin/alarm-device.svg'
import offlineDevice from 'components/Map/devicePin/offline-device.svg'

import ResetButton from "./resetZoomButton";
import { v4 as uuidv4 } from 'uuid';


const { BaseLayer, Overlay } = LayersControl


const siteIconClass = {
    NORMAL: "group-cluster-normal",
    WARNING: "group-cluster-warning",
    ALARM: "group-cluster-alarm",
    OFFLINE: "group-cluster-offline",
}

const handleClick = (event) => {
    window.location = `#/Overview/${event.target.options.siteName}/${event.target.options.deviceName}`;
}


function App(props) {


    const [sites, setSites] = useState({});

    useEffect(() => {

        if ((props.global.device !== null || props.global.device !== undefined) && sites !== {}) {
            setSites(props.global.devices);
        }
    }, [props.global.devices]);


    if (sites !== null) {

        var dataHolder = []
        for (var site in sites) {
            var siteStatus = 'Normal';

            var deviceMarketHolder = []
            for (var device in sites[site]) {
                var sensorData = Object.values(sites[site][device]['sensors'])

                var sensorStatus = sensorData.reduce((state, current) => {
                    var status = state.concat(Object.values(current).map(a => a.status))
                    return status;
                }, []);


                if (sites[site][device]['status'] === 'offline' && siteStatus === 'Normal') {
                    siteStatus = 'offline';
                }
                else if ((sites[site][device]['status'] === 'online' || siteStatus === 'Normal') && sensorStatus !== undefined) {
                    if (sensorStatus.includes('Alarm') || siteStatus === 'Alarm') {
                        siteStatus = 'Alarm'
                    } else if ((sensorStatus.includes('Warning') || siteStatus === 'Warning') && sensorStatus !== undefined) {
                        siteStatus = 'Warning'
                    }
                } else if (siteStatus === 'Normal') {
                    siteStatus = 'offline';
                }

                var deviceIcon = new L.Icon({
                    iconUrl: normalDevice,
                    iconSize: new L.Point(30, 30)
                });


                if (sites[site][device]['status'] === 'offline') {
                    deviceIcon = new L.Icon({
                        iconUrl: offlineDevice,
                        iconSize: new L.Point(30, 30)
                    });
                } else if (sensorStatus !== undefined && sensorStatus.includes('Alarm')) {
                    deviceIcon = new L.Icon({
                        iconUrl: alarmDevice,
                        iconSize: new L.Point(30, 30)
                    });
                } else if (sensorStatus !== undefined && sensorStatus.includes('Warning')) {
                    deviceIcon = new L.Icon({
                        iconUrl: warningDevice,
                        iconSize: new L.Point(30, 30)
                    });
                }





                var deviceMarker = <Marker
                    key={device}
                    position={[sites[site][device]['latitude'], sites[site][device]['longitude']]}
                    siteName={site}
                    deviceName={device}
                    icon={deviceIcon}
                    eventHandlers={{
                        click: e => handleClick(e)
                    }}>
                    <TooltipTemplate
                        device={device}
                        site={site}
                        type={sites[site][device]['iotType']}
                        connectivity={sites[site][device]['status']}
                        sensors={sites[site][device]['sensors']} />
                </Marker>;
                deviceMarketHolder.push(deviceMarker);



            }
            const siteStatusIcon = (cluster) => {
                var siteClass = siteIconClass.NORMAL;

                switch (cluster._group.options.siteStatus) {
                    case "Alarm":
                        siteClass = siteIconClass.ALARM
                        break;
                    case "Warning":
                        siteClass = siteIconClass.WARNING
                        break;
                    case "Offline":
                        siteClass = siteIconClass.OFFLINE
                        break;
                    default:
                        siteClass = siteIconClass.NORMAL;
                        break;
                }

                return new L.divIcon({
                    html: `<span style="margin: auto; display: flex; vertical-align: middle;justify-content:center;">${cluster.getChildCount()}</span>`,
                    className: siteClass,
                    iconSize: L.point(33, 33, true)
                });
            }

            var siteData = <Overlay key={site} checked name={site.toUpperCase()}>
                <LayerGroup>
                    <MarkerClusterGroup
                        key={uuidv4()}
                        // onClick={(e) => console.log('onClick', e)}
                        iconCreateFunction={siteStatusIcon}
                        onMouseOver={(e) => {
                            e.propagatedFrom.bindTooltip(e.target.options.siteName).openTooltip();
                        }}
                        onMouseOut={(e) => {
                            e.propagatedFrom.unbindTooltip();
                        }}
                        siteName={site}
                        siteStatus={siteStatus}
                        animate={true}
                        animateAddingMarkers={true}
                        spiderfyOnMaxZoom={false}
                        disableClusteringAtZoom={15}
                        zoomToBoundsOnClick={true}
                        showCoverageOnHover={false}>
                        {deviceMarketHolder}
                    </MarkerClusterGroup>
                </LayerGroup>
            </Overlay>;
            dataHolder.push(siteData);
        }

        return (<MapContainer
            key={1}
            style={{ height: '80vh', width: 'inherit', margin: '50px' }}
            center={[38.9637, 35.2433]}
            zoom={4}
            scrollWheelZoom={true}>
            <LayersControl position="topright">
                <BaseLayer name="Grayscale Map">
                    <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png" />
                </BaseLayer>
                <BaseLayer checked name="Color Map">
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url='https://{s}.tile.osm.org/{z}/{x}/{y}.png' />
                </BaseLayer>
                {dataHolder}
            </LayersControl>
            <ResetButton />
        </MapContainer>);
    }
    return (
        <Loader title="Getting devices" />
    );
}


export default connect((state) => state, null)(App);

