import React from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Paper, TableContainer, Table, TableCell, TableHead, TableRow, TableBody } from '@material-ui/core';
import moment from 'moment';
import Loader from 'components/Loader';
import { SHOW_NOTIFICATION } from 'reducers/notification/constants';
import PropTypes from 'prop-types';
import { Refresh } from '@material-ui/icons';
class MonitoringObjectTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            site: this.props.site,
            device: this.props.device,
            sensor: this.props.sensor,
            lastRefresh: new moment(),
            loading: false
        }
    }

    componentDidMount() {
        this.props.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.site !== this.props.site ||
            prevProps.device !== this.props.device ||
            prevProps.sensor !== this.props.sensor) {
            this.props.fetchData();
        }

        // TO HANDLE FIRST LOAD AS FRONTEND GET TOKEN FIRST ONLY CAN QUERY DATA            
        if (prevProps.global.devices !== this.props.global.devices) {
            this.props.fetchData();
        }
    }

    fetchData = () => {
        this.props.fetchData();
        this.setState({
            lastRefresh: new moment()
        });
    }

    render() {


        return <div>
            {
                this.state.loading
                    ? <Loader title='Loading data' />
                    : (
                        <Grid container justifyContent='center' style={{ textAlign: 'center' }}>
                            <Grid item>
                                <Paper className={'paper'} elevation={0}>
                                    <TableContainer className={'table-container'} style={{ minWidth: '750px' }}>
                                        <Table>
                                            {(this.props.sensorsData.length > 0) ? null : <caption id={'empty-row'}>Data is empty</caption>}
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={'table-header'} align='center'>Monitoring Object</TableCell>
                                                    <TableCell className={'table-header'} align='center'>Warning</TableCell>
                                                    <TableCell className={'table-header'} align='center'>Alarm</TableCell>
                                                    <TableCell className={'table-header'} align='center'>Value</TableCell>
                                                    <TableCell className={'table-header'} align='center'>Timestamp</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.props.sensorsData.map((row, index) => (
                                                    <TableRow className={(index % 2) ? 'even-row' : 'odd-row'} key={index}>
                                                        <TableCell align='center'>{row.object}</TableCell>
                                                        <TableCell align='center'>{row.warning}</TableCell>
                                                        <TableCell align='center'>{row.alarm}</TableCell>
                                                        {
                                                            (row.value > row.warning) ?
                                                                (row.value > row.alarm) ?
                                                                    <TableCell style={{ backgroundColor: '#ff000040' }} align='center'><span style={{ color: '#c00000' }}>{row.value} mm/s</span></TableCell>
                                                                    :
                                                                    <TableCell style={{ backgroundColor: '#f9f33e99' }} align='center'><span style={{ color: '#916500' }}>{row.value} mm/s</span></TableCell>
                                                                :
                                                                <TableCell style={{ backgroundColor: '#00800047' }} align='center'><span style={{ color: '#006800' }}>{row.value} mm/s</span></TableCell>
                                                        }
                                                        <TableCell align='center'>{row.timestamp}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>

                                <br />

                                <Button id='LindeButton' startIcon={<Refresh />}>
                                    <span className='button-text' onClick={this.fetchData} > Last Refresh: {this.state.lastRefresh.format('DD MMM YYYY hh:mm:ss A')}</span>
                                </Button>
                            </Grid>
                        </Grid>

                    )
            }
            </div>
    }
}

let mapStateToProps = (state) => state;

let mapDispatchToProps = dispatch => {
    return {
        showNotification: function (msg, intent) {
            dispatch({
                type: SHOW_NOTIFICATION,
                message: msg,
                intent: intent
            });
        }
    };
};

MonitoringObjectTable.propTypes = {
    site: PropTypes.string,
    device: PropTypes.string,
    sensor: PropTypes.string
};


export default connect(mapStateToProps, mapDispatchToProps)(MonitoringObjectTable);
