import { combineReducers } from 'redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';

import authReducer from 'reducers/authentication';
import navigationReducer from 'reducers/navigation';
import globalReducer from 'reducers/global';
import notificationReducer from 'reducers/notification';


const reduce = combineReducers({
    authentication: authReducer,
    navigation: navigationReducer,
    global: globalReducer,
    notification: notificationReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(reduce, composeEnhancers(applyMiddleware(thunkMiddleware)));
