import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Paper } from '@material-ui/core';
import moment from 'moment';
import { SHOW_NOTIFICATION } from 'reducers/notification/constants';
import axios from 'axios';

import MonitoringObjectTable from 'pages/Dashboard/Sensor/monitoringObjectTable'
import MonitoringObjectBarChart from 'pages/Dashboard/Sensor/monitoringObjectBarChart'
import MonitoringObjectTrendChart from 'pages/Dashboard/Sensor/monitoringObjectTrendChart'
import FFTPanel from 'pages/Dashboard/Sensor/FFTPanel'

import { apiEndpoint } from 'config/settings';

const Sensor = props => {


    const currentSite = (window.location.hash).split('/')[2];
    const currentDevice = (window.location.hash).split('/')[3];
    const currentSensor = (window.location.hash).split('/')[4];
    
    const deviceInfo = props.global.devices?.[currentSite]?.[currentDevice];
    const accessToken = props.authentication.accessToken;
    const masterDateTime = props.navigation;
   
    const [optimumWidth, setOptimumWidth] = useState(window.innerWidth * 95 / 100);

    const handleResize = () => {
        setOptimumWidth(window.innerWidth * 95 / 100);
    };

    const [dateTime, setDateTime] = useState([moment().subtract(2, "days"), moment()]);

    useEffect(() => {
        if (masterDateTime.startDateTime && masterDateTime.endDateTime) {
            setDateTime([moment(masterDateTime.startDateTime), moment(masterDateTime.endDateTime)]);
        }
    }, [masterDateTime]);

    useEffect(() => {
        document.title = 'EDAS - Sensor';
        
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    /**
     * Monitoring Objects
     */
    const [sensorsData, setSensorsData] = useState([]);

    const createRow = (sensor, object, warning, alarm, value, unit, timestamp) => {
        return { sensor, object, warning, alarm, value, unit, timestamp };
    };

    const getMonitoringObjdata = () => {

        if (deviceInfo && props.authentication.kustoToken && deviceInfo.deviceId) {

            let headers = {
                Authorization: `Bearer ${accessToken}`
            };

            axios.get(`${apiEndpoint.Threshold.GetThresholdByDevice}/${deviceInfo?.deviceId}`, {headers}).then(thresholdData => {
                thresholdData = thresholdData.data;

                let deviceSensors = deviceInfo.sensors;
                let masterList = [];

                Object.keys(deviceSensors).map(sensorName => {
                    let sensor = deviceSensors[sensorName];

                    Object.keys(sensor).map(objectName => {
                        let object = sensor[objectName];
                        masterList.push({
                            sensor: sensorName,
                            object: objectName.toUpperCase(),
                            value: object.value,
                            status: object.status,
                            timestamp: object.timestamp
                        });
                        return null;
                    });
                    return null;
                });

                masterList = masterList.filter(x => x.sensor.toUpperCase() === currentSensor.toUpperCase());
                
                let sensorObjectThreshold = [];
                for (let i=0; i<masterList.length; i++) {
                    sensorObjectThreshold = [];
                    sensorObjectThreshold = thresholdData.filter(x => 
                        x.monitoringObj.name.toUpperCase() === masterList[i].object.toUpperCase() && 
                        x.sensor.name.toUpperCase() === currentSensor.toUpperCase());

                    if (sensorObjectThreshold.length > 0) {
                        masterList[i]['alarm'] = sensorObjectThreshold[0].alarm;
                        masterList[i]['warning'] = sensorObjectThreshold[0].warning;
                        masterList[i]['unit'] = sensorObjectThreshold[0].unit;
                    }
                    else {
                        // Handle missing monitoring object data
                        masterList[i]['alarm'] = 0;
                        masterList[i]['warning'] = 0;
                        masterList[i]['unit'] = 'mm/s';
                    }
                }

                let masterListRows = [];
                for (let i=0; i<masterList.length; i++) {
                    masterListRows.push(createRow(
                        masterList[i].sensor,
                        masterList[i].object,
                        masterList[i].warning,
                        masterList[i].alarm,
                        masterList[i].value.toFixed(4),
                        masterList[i].unit,
                        moment(masterList[i].timestamp).format('YYYY-MM-DD hh:mm:ss A')
                    ));
                }
                
                setSensorsData(masterListRows);
            })
            .catch(function (error) {
                props.showNotification(error.response, 'danger')
            });
        }
    }
    
    const renderMonitoringObjects = () => {
        return (
            <>
                <MonitoringObjectTable site={currentSite}
                    device = {currentDevice}
                    sensor ={currentSensor}
                    fetchData ={getMonitoringObjdata}
                    sensorsData={sensorsData}
                />

                <MonitoringObjectBarChart sensorsData={sensorsData} />

            </>
        );
    };

    /**
     * Trends
     */

    const plotTrends = () => {
        var trends = [];
        var monitoringObjects = ['1x', '2x', '3x', '2lf', 'subx', 'multix', 'iso10816'];
        
        for (var x=0; x<7; x++) {
            trends.push(
                <Grid key={x} container justifyContent='center' style={{ textAlign: 'center', marginTop: '40px' }}>
                    <Grid item>
                        <h1 className={'header1'}>{monitoringObjects[x]}</h1>
                        <Paper style={{ backgroundColor: 'white', width: optimumWidth, height: '450px' }} elevation={0}>
                                <MonitoringObjectTrendChart 
                                    site={currentSite}
                                    device={currentDevice} 
                                    sensor={currentSensor} 
                                    monitoringObject={monitoringObjects[x]}  
                                    startTime={dateTime[0]}
                                    endTime={dateTime[1]}                                    
                                />
                        </Paper>
                    </Grid>
                </Grid>
            );
        }

        return trends;
    };


    /**
     * FFT Charts
     */
    const renderSpectrumPanel = () => {
        return (
                <FFTPanel site={currentSite}
                    device={currentDevice}
                    sensor={currentSensor}
                    startTime={dateTime[0]}
                    endTime={dateTime[1]}
                />
        )
    }


    return (
        <>
            {renderMonitoringObjects()}

            <div style={{ paddingTop: '40px' }}></div>

            {plotTrends()}
            <div style={{ paddingTop: '40px' }}></div>

            {renderSpectrumPanel()}
        </>
    );
};


let mapStateToProps = (state) => state;

let mapDispatchToProps = dispatch => {
    return {
        showNotification: function (msg, intent) {
            dispatch({
                type: SHOW_NOTIFICATION,
                message: msg,
                intent: intent
            });
        }
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Sensor);

