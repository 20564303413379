



import React from 'react';
import { connect } from 'react-redux';

import { Snackbar, Alert} from '@mui/material' 

class AlertNotification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        var Severity = "info"


        if (this.props.intent === 'danger') {
            Severity = "error"
        }
        else if (this.props.intent === 'warning') {
            Severity = "warning"
        }
        else if (this.props.intent === 'success') {
            Severity = "success"
        }
        else if (this.props.intent === 'primary') {
            Severity = "info"
        }
        else if (this.props.intent === 'none') {
            Severity = "info"
        } else {
            Severity = "info"
        }

        return (
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right"}} open={this.props.showNotification} autoHideDuration={6000} onClose={this.props.closeNotification}>
                <Alert onClose={this.props.closeNotification} severity={Severity} sx={{ width: '100%' }}>
                    {this.props.notificationMessage}
                </Alert>
            </Snackbar>

     
        );
    }
}

let mapStateToProps = (state) => {
    return {
        notificationMessage: state.notification.notificationMessage,
        showNotification: state.notification.showNotification,
        intent: state.notification.intent,
    }
}

let mapDispatchToProps = dispatch => {
    return {
        closeNotification: function () {
            dispatch({
                type: "CLOSE_NOTIFICATION"
            });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertNotification);