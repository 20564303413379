import { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
    Button, Grid, Paper,
    TableContainer, Table, TableCell, TableHead, TableRow, TableBody,
    Select, FormControl, Dialog, DialogContent, TextField
} from '@material-ui/core';
import { Description, Refresh } from '@material-ui/icons';
import MotorSVG from 'assets/images/motor';
import { updateDateTime } from 'reducers/navigation/actions';
import moment from 'moment-timezone';
import Comment from 'components/Comment';
import axios from 'axios';
import { apiEndpoint } from 'config/settings';
import authentication from 'reducers/authentication';
import AlarmPanel from 'pages/Dashboard/Device/alarmPanel'

const Device = props => {

    const dispatch = useDispatch();

    let location = window.location.hash;
    let site = location.split('/')[2];
    let device = location.split('/')[3];

    const [deviceInfo, setDeviceInfo] = useState({});

    useEffect(() => {
        document.title = 'EDAS - Device';
        dispatch(updateDateTime(moment().hour(0).minute(0).second(0).millisecond(0), moment()));
    }, [dispatch]);

    useEffect(() => {
        getReport();
        getMonitoringObjects();
        getAlarms();
        identifyDeviceStatus();
    }, [location]);

    useEffect(() => {
        if (props.global.devices && props.global.devices.hasOwnProperty(site)) {
            if (props.global.devices[site].hasOwnProperty(device)) {
                setDeviceInfo(props.global.devices[site][device])
            }
        }
        identifyDeviceStatus();


    }, [props])


    useEffect(() => {
        getMetadata();
        getReport();
        getMonitoringObjects();
        getAlarms();
        identifyDeviceStatus();
    }, [deviceInfo]);


    const createRow = (item1, item2, item3, item4, item5, item6, item7) => {
        return { item1, item2, item3, item4, item5, item6, item7 };
    };

    /* ----------------------------------------------------------------------------------- */
    // #region Motor SVG
    /* ----------------------------------------------------------------------------------- */

    const motorStatusType = { Normal: 'NORMAL', Warning: 'WARNING', Alarm: 'ALARM' };
    const [deviceStatus, setDeviceStatus] = useState('Normal');

    const identifyDeviceStatus = () => {
        let sensors = deviceInfo?.sensors;
        let isAlarm = false;
        let isWarning = false;

        if (sensors) {

            if (sensors['dE_a']['iso10816'].value > 4.5 ||
                sensors['dE_h']['iso10816'].value > 4.5 ||
                sensors['dE_v']['iso10816'].value > 4.5 ||
                sensors['ndE_v']['iso10816'].value > 4.5) {

                setDeviceStatus(motorStatusType.Alarm);

            } else if (sensors['dE_a']['iso10816'].value > 2.8 ||
                sensors['dE_h']['iso10816'].value > 2.8 ||
                sensors['dE_v']['iso10816'].value > 2.8 ||
                sensors['ndE_v']['iso10816'].value > 2.8) {

                setDeviceStatus(motorStatusType.Warning);

            } else {

                setDeviceStatus(motorStatusType.Normal);

            }


            // Object.keys(sensors).map(sensorName => {
            //     let objects = sensors[sensorName];
            //     Object.keys(objects).map(objectName => {
            //         if (objects[objectName].status.toUpperCase() === motorStatusType.Alarm) {
            //             isAlarm = true;
            //         }
            //         else if (objects[objectName].status.toUpperCase() === motorStatusType.Warning) {
            //             isWarning = true;
            //         }
            //     });
            // });

            // if (isAlarm) {
            //     setDeviceStatus(motorStatusType.Alarm);
            // }
            // else if (isWarning) {
            //     setDeviceStatus(motorStatusType.Warning);
            // }
        }

    };

    // #endregion
    /* ----------------------------------------------------------------------------------- */

    const [alarmData, setAlarmData] = useState([]);
    const getAlarms = () => {

        if (deviceInfo && deviceInfo.deviceId) {
            let headers = {
                Authorization: `Bearer ${props.authentication.accessToken}`
            };

            axios.get(`${apiEndpoint.Alarm.GetAlarmByDeviceId}/${deviceInfo?.deviceId}`, { headers })
                .then(data => {
                    data = data.data;

                    let alarmList = [];
                    for (let i = 0; i < data.length; i++) {
                        alarmList.push(createRow(
                            moment(data[i].createdTimestamp + 'z'),
                            data[i].sensor.name,
                            data[i].monitoringObject.name.toUpperCase(),
                            data[i].valueBeforeAlarm,
                            data[i].valueAfterAlarm,
                            data[i].threshold
                        ));
                    }
                    var sortedAlarmList = alarmList.sort(function (a, b) {
                        return b.item1 - a.item1;
                    });

                    setAlarmData(sortedAlarmList);
                })
                .catch(error => {
                    console.log('Fail to get Alarm > ', error);
                });
        }
    };


    /* ----------------------------------------------------------------------------------- */
    // #region Metadata
    /* ----------------------------------------------------------------------------------- */

    const [metadata, setMetadata] = useState([]);

    const getMetadata = () => {
        let data = deviceInfo?.metadata;
        let metaData = [];

        if (data) {
            metaData.push(
                createRow('P&ID Equipment No.', data.pidEquipment),
                createRow('Power (kW)', data.power),
                createRow('Rated Speed (rpm)', data.ratedSpeed),
                createRow('Driver Poles', data.driverPoles),
                createRow('Line Frequency (Hz)', data.lineFrequence),
                createRow('Historian Server', data.historianServer),
                createRow('Plant No.', deviceInfo.plantNumber),
            );
            setMetadata(metaData);
        }
    };

    // #endregion
    /* ----------------------------------------------------------------------------------- */


    /* ----------------------------------------------------------------------------------- */
    // #region Report Panel
    /* ----------------------------------------------------------------------------------- */

    const [reportStart, setReportStart] = useState(moment().subtract(1, 'days').format('YYYY.MM.DD HH:mm:ss'));
    const [reportEnd, setReportEnd] = useState(moment().format('YYYY.MM.DD HH:mm:ss'));
    const [reportComment, setReportComment] = useState('');
    const [reportData, setReportData] = useState([]);

    const getReport = () => {
        if (deviceInfo && deviceInfo.deviceId) {

            let headers = {
                Authorization: `Bearer ${props.authentication.accessToken}`
            };

            axios.get(`${apiEndpoint.Report.GetReportByDeviceId}/${deviceInfo?.deviceId}`, { headers })
                .then(data => {
                    data = data.data;

                    let reportData = [];
                    for (let i = 0; i < data.length; i++) {
                        reportData.push(
                            createRow(data[i].reportSerialNumber, data[i].creationTimestamp, data[i].userName, data[i].comment, <a href={data[i].reportUrl} target="_blank" rel="noopener noreferrer">View</a>)
                        );
                    }

                    setReportData(reportData);
                })
                .catch(error => {
                    console.log('Fail to get Report data > ', error);
                });

        }
    };

    const getReportDateTime = (date) => {
        date = date.replace(/\./g, '-');
        date = date.replace(' ', 'T');
        console.log(moment(date));
        return date;
    };

    // TO TEST: Backend code changed
    const generateReport = () => {
        let config = {
            headers: { Authorization: `Bearer ${props.authentication.accessToken}` }
        };

        let body = {
            deviceId: deviceInfo?.deviceId,
            creator: props.authentication.idToken?.name,
            creatorId: props.authentication.idToken?.oid,
            startTime: moment(getReportDateTime(reportStart)).utc().format('YYYY-MM-DDTHH:mm:ss'),
            endTime: moment(getReportDateTime(reportEnd)).utc().format('YYYY-MM-DDTHH:mm:ss'),
            comment: reportComment,
            timezone: moment.tz.guess()
        };

        console.log('Check the payload here', body)
        console.log('Check the payload here', props.authentication.idToken)
        console.log('Check the payload here', authentication)

        axios.post(`${apiEndpoint.Report.GenerateReport}`, body, config).then(data => {
            data = data.data;

            // TO DO: Toast success message
            getReport();
        }).catch(error => {
            console.log('Fail to generate report > ', error);
        });

        setOpenComment(false);
    };

    // #endregion
    /* ----------------------------------------------------------------------------------- */


    /* ----------------------------------------------------------------------------------- */
    // #region Monitoring Object
    /* ----------------------------------------------------------------------------------- */

    const [monitorObject, setMonitorObject] = useState('ISO10816');
    const [monitoringObjectData, setMonitoringObjectData] = useState([]);
    const [monObjLastRefresh, setMonObjLastRefresh] = useState(moment().format('DD MMM YYY hh:mm:ss A'));

    // TO FIX: This is not needed when Backend is not returning GUID values
    // const getTransformSensor = (sensor) => {
    //     switch (sensor.toUpperCase()) {
    //         case 'DE_A':
    //             return 'ADE';

    //         case 'DE_H':
    //             return 'HDE';

    //         case 'DE_V':
    //             return 'VDE';

    //         case 'NDE_v':
    //             return 'VNDE';

    //         default:
    //             return 'ADE';
    //     }
    // };

    const getMonitoringObjects = () => {
        if (deviceInfo && deviceInfo.deviceId) {


            let headers = {
                Authorization: `Bearer ${props.authentication.accessToken}`
            };

            axios.get(`${apiEndpoint.Threshold.GetThresholdByDevice}/${deviceInfo?.deviceId}`, { headers })
                .then(thresholdData => {
                    thresholdData = thresholdData.data;
                    let deviceSensors = deviceInfo?.sensors;
                    let masterList = [];

                    Object.keys(deviceSensors).sort().map(sensorName => {
                        let sensor = deviceSensors[sensorName];


                        Object.keys(sensor).map(objectName => {
                            let object = sensor[objectName];

                            masterList.push({
                                sensor: sensorName,
                                object: objectName.toUpperCase(),
                                value: object.value,
                                status: object.status,
                                timestamp: object.timestamp
                            });
                        });
                    });


                    let sensorObjectThreshold = [];
                    for (let i = 0; i < masterList.length; i++) {
                        sensorObjectThreshold = [];
                        sensorObjectThreshold = thresholdData.filter(x => x.monitoringObj.name.toUpperCase() === masterList[i].object.toUpperCase()
                            && x.sensor.name.toUpperCase() === masterList[i].sensor.toUpperCase());

                        if (sensorObjectThreshold.length > 0) {
                            masterList[i]['alarm'] = sensorObjectThreshold[0].alarm;
                            masterList[i]['warning'] = sensorObjectThreshold[0].warning;
                            masterList[i]['unit'] = sensorObjectThreshold[0].unit;
                        }
                        else {
                            // Handle missing monitoring object data
                            masterList[i]['alarm'] = 0;
                            masterList[i]['warning'] = 0;
                            masterList[i]['unit'] = 'mm/s';
                        }
                    }

                    let masterListRows = [];
                    for (let i = 0; i < masterList.length; i++) {
                        masterListRows.push(createRow(
                            masterList[i].sensor,
                            masterList[i].object,
                            masterList[i].warning,
                            masterList[i].alarm,
                            masterList[i].value.toFixed(4),
                            moment(masterList[i].timestamp).format('YYYY-MM-DD hh:mm:ss A')
                        ));
                    }

                    setMonitoringObjectData(masterListRows);
                })
                .catch(error => {
                    console.log('Fail to get Threshold > ', error);
                });
        }
    };

    const onChangeMonitorObject = (event) => {
        setMonitorObject(event);
    };

    const refreshMonitoringObject = () => {
        setMonObjLastRefresh(moment().format('DD MMM YYY hh:mm:ss A'));
        getMonitoringObjects();
    };

    useEffect(() => {
        onChangeMonitorObject(monitorObject);
    }, [location, site, monitorObject]);

    //#endregion
    /* ----------------------------------------------------------------------------------- */


    /* ----------------------------------------------------------------------------------- */
    // #region Comment
    /* ----------------------------------------------------------------------------------- */

    const [openComment, setOpenComment] = useState(false);

    // #endregion
    /* ----------------------------------------------------------------------------------- */

    return (
        <>
            {/* Chatbox SideBar */}
            <Comment deviceId={deviceInfo?.deviceId} />

            {/* Generate New Report Overlay */}
            <Dialog
                open={openComment}
                BackdropProps={{
                    style: { backgroundColor: 'rgba(64,64,64,0.1)' }
                }}
                maxWidth='xl'
                onClose={() => { setOpenComment(false); }}
            >
                <DialogContent>
                    <h1 style={{ textTransform: 'none', textAlign: 'left' }}>
                        <span style={{ fontSize: '29px', color: '#005591' }}>Report Panel</span>
                    </h1>
                    <br />
                    <form noValidate autoComplete='off'>
                        <TextField label='From' defaultValue={reportStart} onChange={(e) => setReportStart(e.target.value)} style={{ borderBottom: '1px solid #005591', color: '#005591' }} />
                        <TextField label='To' defaultValue={reportEnd} onChange={(e) => setReportEnd(e.target.value)} style={{ marginLeft: '20px', borderBottom: '1px solid #005591', color: '#005591' }} />
                        <br /><br />
                        <TextField label='Comment' value={reportComment} onChange={(e) => setReportComment(e.target.value)} style={{ borderBottom: '1px solid #005591', width: '100%', color: '#005591', padding: '0px 6px' }} multiline />
                    </form>
                </DialogContent>
                <br />
                <Button
                    variant='contained'
                    color='secondary'
                    id='LindeButton'
                    startIcon={<Description />}
                    style={{ textTransform: 'none', boxShadow: 'none', height: '50px' }}
                    onClick={() => generateReport()}
                >
                    Generate Report
                </Button>
            </Dialog>

            {/* Motor SVG Image */}
            <Grid container justifyContent='center' style={{ textAlign: 'center', display: 'flex' }}>
                <Grid item style={{ justifyContent: 'center', textAlign: 'center' }}>
                    <Paper style={{ backgroundColor: 'white', width: '500px' }} elevation={0}>
                        <div style={{ padding: '30px' }}>
                            <MotorSVG alarmLevel={deviceStatus} duration='0.1s' {...props} />
                        </div>
                    </Paper>
                </Grid>
            </Grid>

            {/* Metadata */}
            <Grid container justifyContent='center' className={'center-mt50'}>
                <Grid item>
                    <Paper className={'paper'} elevation={0}>
                        <TableContainer className={'table-container'}>
                            <Table>
                                {(metadata.length > 0) ? null : <caption id={'empty-row'}>Data is empty</caption>}
                                <TableHead>
                                    <TableRow>
                                        <TableCell id={'table-header'} align='center'>No</TableCell>
                                        <TableCell id={'table-header'} align='center'>Description</TableCell>
                                        <TableCell id={'table-header'} align='center'>Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {metadata.map((row, index) => (
                                        <TableRow className={(index % 2) ? 'even-row' : 'odd-row'} key={index}>
                                            <TableCell align='center'>{++index}</TableCell>
                                            <TableCell align='center'>{row.item1}</TableCell>
                                            <TableCell align='center'>{row.item2}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>

            {/* Report Panel */}
            <Grid container justifyContent='center' className={'center-mt80'}>
                <Grid item>
                    <Grid container justifyContent='space-between' style={{ textAlign: 'center' }}>
                        <Grid item>
                            <h1 className={'header1'}>Report Panel</h1>
                        </Grid>
                    </Grid>

                    <Paper className={'paper'} style={{ width: '1200px' }} elevation={0}>
                        <TableContainer className={'table-container'}>
                            <Table>
                                {(reportData.length > 0) ? null : <caption id={'empty-row'}>Data is empty</caption>}
                                <TableHead>
                                    <TableRow>
                                        <TableCell id={'table-header'} align='center'>No</TableCell>
                                        <TableCell id={'table-header'} align='center'>Report ID</TableCell>
                                        <TableCell id={'table-header'} align='center'>Timestamp</TableCell>
                                        <TableCell id={'table-header'} align='center'>Creator</TableCell>
                                        <TableCell id={'table-header'} align='center'>Comment</TableCell>
                                        <TableCell id={'table-header'} align='center'>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {reportData.map((row, index) => (
                                        <TableRow className={(index % 2) ? 'even-row' : 'odd-row'} key={index}>
                                            <TableCell align='center'>{++index}</TableCell>
                                            <TableCell align='center'>{row.item1}</TableCell>
                                            <TableCell align='center'>{row.item2}</TableCell>
                                            <TableCell align='center'>{row.item3}</TableCell>
                                            <TableCell align='center'>{row.item4}</TableCell>
                                            <TableCell align='center'>{row.item5}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>

                    <br />

                    <Button id='LindeButton' startIcon={<Description />} onClick={() => setOpenComment(true)} style={{ margin: '0px 20px' }}>
                        <span className='button-text'>Generate Report</span>
                    </Button>

                    {/* TO REMOVE: 
                    <Button id='LindeButton' startIcon={<Refresh />} onClick={handleRefresh}>
                        <span className='button-text'>Last Refresh: {lastRefresh}</span>
                    </Button> */}
                </Grid>
            </Grid>

            {/* Alarm Panel */}
            <AlarmPanel {...props}
                deviceInfo={deviceInfo}
                createRow={createRow}
                getAlarms={getAlarms}
                alarmData={alarmData}
            />


            {/* Monitoring Object Panel */}
            <Grid container justifyContent='center' className={'center-mt80'}>
                <Grid item>
                    <Grid container justifyContent='space-between' style={{ textAlign: 'center' }}>
                        <Grid item>
                            <h1 className={'header1'}>Monitoring Object Panel</h1>
                        </Grid>
                        <Grid item>
                            <FormControl>
                                <Select
                                    id='linde-dropdown'
                                    style={{ width: '150px', border: '1px solid #B9CDD7', background: 'white', boxShadow: 'inset 2px 2px 6px #B9CDD7', color: '#7FAAC8' }}
                                    native
                                    value={monitorObject}
                                    onChange={(item) => onChangeMonitorObject(item.target.value)}
                                    disabled={(monitoringObjectData.length > 0) ? false : true}
                                >
                                    <option value='1X'>1X</option>
                                    <option value='2X'>2X</option>
                                    <option value='3X'>3X</option>
                                    <option value='2LF'>2LF</option>
                                    <option value='SUBX'>SUBX</option>
                                    <option value='MULTIX'>MULTIX</option>
                                    <option value='ISO10816'>ISO10816</option>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Paper className={'paper'} style={{ width: '1200px' }} elevation={0}>
                        <TableContainer className={'table-container'}>
                            <Table>
                                {(monitoringObjectData.length > 0) ? null : <caption id={'empty-row'}>Data is empty</caption>}
                                <TableHead>
                                    <TableRow>
                                        <TableCell id={'table-header'} align='center'>Sensor</TableCell>
                                        <TableCell id={'table-header'} align='center'>Monitoring Object</TableCell>
                                        <TableCell id={'table-header'} align='center'>Warning</TableCell>
                                        <TableCell id={'table-header'} align='center'>Alarm</TableCell>
                                        <TableCell id={'table-header'} align='center'>Value</TableCell>
                                        <TableCell id={'table-header'} align='center'>Timestamp</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {monitoringObjectData
                                        .filter(x => x.item2 === monitorObject)
                                        .map((row, index) => (
                                            <TableRow className={(index % 2) ? 'even-row' : 'odd-row'} key={index}>
                                                <TableCell style={{ color: '#005591' }} align='center'>{row.item1}</TableCell>
                                                <TableCell style={{ color: '#005591' }} align='center'>{row.item2}</TableCell>
                                                <TableCell style={{ color: '#005591' }} align='center'>{row.item3}</TableCell>
                                                <TableCell style={{ color: '#005591' }} align='center'>{row.item4}</TableCell>
                                                {
                                                    (row.item5 > row.item3) ?
                                                        (row.item5 > row.item4) ?
                                                            <TableCell style={{ backgroundColor: '#ff000040' }} align='center'><span style={{ color: '#c00000' }}>{row.item5} mm/s</span></TableCell>
                                                            :
                                                            <TableCell style={{ backgroundColor: '#f9f33e99' }} align='center'><span style={{ color: '#916500' }}>{row.item5} mm/s</span></TableCell>
                                                        :
                                                        <TableCell style={{ backgroundColor: '#00800047' }} align='center'><span style={{ color: '#006800' }}>{row.item5} mm/s</span></TableCell>
                                                }
                                                <TableCell style={{ color: '#005591' }} align='center'>{row.item6}</TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>

                    <br />

                    <Button id='LindeButton' startIcon={<Refresh />} onClick={refreshMonitoringObject}>
                        <span className='button-text'>Last Refresh: {monObjLastRefresh}</span>
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};


let mapStateToProps = (state) => state;

export default connect(mapStateToProps, null)(Device)