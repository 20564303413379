import React from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Paper } from '@material-ui/core';
import moment from 'moment';
import { SHOW_NOTIFICATION } from 'reducers/notification/constants';
import axios from 'axios';
import { getNewCancelToken } from 'config/settings';

import FFTSpectrum from './FFTCharts/FFTSpectrum'
import FFTWaterfall from './FFTCharts/FFTWaterfall'
import FFTSpectrogram from './FFTCharts/FFTSpectrogram'

const FFTTypes = ['Peak', 'RMS'];

class MonitoringObjectSpectrumPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            loadingTimestamps: false,
            loadingSpectrum: false,
            loadingSpectra: false,

            cancelToken: null,

            timestamps: [],

            selectedTimestampRange: {
                start: 0,
                end: 9
            },

            spectrumMode: 'FFT',
            FFTType: 'Peak',
            reference: false,
            fftDataRevision: 0,
            uirevision: 0,
            optimumWidth: window.innerWidth * 95 / 100,
            sliderCount: 0,
            fftValue: 0,
            fftInitialValue: 0,

            fftData: {
                xData: [],
                yData: [],
                yDataReference: [],
                yDataRefDate: null
            },

            fftSpectraData: {
                spectra: [],
                timestamps: []
            },

            loadingPercentage: 0
        }

    }
    componentDidMount() {
        this.getAvailableTimestamps();
    }


    componentDidUpdate(prevProps, prevState) {

        if (prevProps.device !== this.props.device ||
            prevProps.sensor !== this.props.sensor ||
            prevProps.startTime !== this.props.startTime ||
            prevProps.endTime !== this.props.endTime ||
            prevProps.authentication.kustoToken !== this.props.authentication.kustoToken
        ) {
            if (this.props.authentication.kustoToken) {
                this.getAvailableTimestamps();
            }
        }


        /// Handle the selected value change
        if (prevState.fftValue !== this.state.fftValue && this.state.timestamps.length !== 0) {
            this.getSpectrum();
        }

        /// To handle when the fftType changed from FFT to Spectrogram or Waterfall

        if (prevState.spectrumMode !== this.state.spectrumMode && this.state.fftSpectraData.spectra.length === 0) {
            this.getSpectra();
        }
    }

    getSpectrum = () => {

        this.setState({
            loadingSpectrum: true,
            fftDataRevision: this.state.fftDataRevision + 1
        });

        axios.get('api/spectra/' + this.props.device + '/' + this.props.sensor + '/' + this.state.timestamps[this.state.fftValue],
            {
                headers: {
                    'Authorization': 'Bearer ' + this.props.authentication.kustoToken
                }
            })
            .then(res => {
                //calculate timeaxis
                let timeaxis = [];
                let freqStart = parseFloat(res.data.freqStart);
                let freqStep = parseFloat(res.data.freqStep);
                for (let i = 0; i < res.data.values.length; i++) {
                    timeaxis.push((i * freqStep) + freqStart)
                }

                this.setState({
                    fftData: {
                        yData: res.data.values,
                        xData: timeaxis
                    },
                    freqStart: freqStart,
                    freqStep: freqStep,
                    loadingSpectrum: false,
                    fftDataRevision: this.state.fftDataRevision + 1
                });
            }).catch(error => {
                this.props.showNotification(error.message);
                this.setState({
                    fftData: {
                        yData: [],
                        xData: []
                    },
                    freqStart: 0,
                    freqStep: 0,
                    fftDataRevision: this.state.fftDataRevision + 1,
                    loadingSpectrum: false
                });
            });

    }

    async getSpectra() {

        this.setState({
            uirevision: this.state.uirevision + 1,
            loadingSpectra: true,
            loadingPercentage: 0
        })

        var startCount = this.state.selectedTimestampRange.start;
        var endCount = this.state.selectedTimestampRange.end;

        var spectrumAvailable = endCount - startCount;
        var bucketCount = Math.floor(spectrumAvailable / 50);

        bucketCount = bucketCount < 1 ? 1 : bucketCount;

        // Axios cancellation token - This is to cancel axios request
        let newToken = getNewCancelToken();
        this.setState({
            cancelToken: newToken
        });
        let newCancelToken = newToken.token;

        for (var i = startCount; i < endCount && !(newCancelToken.reason); i = i + bucketCount) {
            await axios.get('api/spectra/' + this.props.device + '/' + this.props.sensor + '/' + this.state.timestamps[i],
                {
                    headers: {
                        'Authorization': 'Bearer ' + this.props.authentication.kustoToken
                    }
                }, {cancelToken: newCancelToken})
                .then(res => {

                    var spectra = this.state.fftSpectraData.spectra;
                    spectra.push(res.data.values)

                    var timestamps = this.state.fftSpectraData.timestamps;
                    timestamps.push(res.data.timestamp)
                    this.setState({
                        fftSpectraData: {
                            spectra: spectra,
                            timestamps: timestamps
                        },
                        fftDataRevision: this.state.fftDataRevision + 1,
                        loadingPercentage: this.state.loadingPercentage + 2
                    })
                }).catch(error => {
                    this.props.showNotification(error.message);
                    // this.setState({
                    //     fftData: {
                    //         yData: [],
                    //         xData: []
                    //     },
                    //     freqStart: 0,
                    //     freqStep: 0,
                    //     fftDataRevision: this.state.fftDataRevision + 1,
                    //     loadingSpectrum: false
                    // });
                });
        }

        this.setState({
            loadingSpectra: false,
            loadingPercentage: 0
        })
    }

    increaseSliderValue = () => {
        if (this.state.timestamps.length > 0) {
            if (this.state.fftValue < this.state.timestamps.length) {
                this.setState({
                    fftValue: this.state.fftValue + 1
                })
            }
        }
    };

    decreaseSliderValue = () => {
        if (this.state.timestamps.length > 0) {
            if (this.state.fftValue + 1 > 1) {

                this.setState({
                    fftValue: this.state.fftValue - 1
                })
            }
        }
    };

    increaseSliderRangeValue = () => {
        if (this.state.timestamps.length > 0) {
            if (this.state.selectedTimestampRange.end < this.state.timestamps.length) {
                this.setState({
                    selectedTimestampRange: {
                        start: this.state.selectedTimestampRange.start + 1,
                        end: this.state.selectedTimestampRange.end + 1
                    },
                    fftSpectraData: {
                        spectra: [],
                        timestamps: []
                    }
                },() => {
                    this.getSpectra();
                })
            }
        }
    }

    decreaseSliderRangeValue = () => {
        if (this.state.timestamps.length > 0) {
            if (this.state.selectedTimestampRange.start + 1 > 1) {
                this.setState({
                    selectedTimestampRange: {
                        start: this.state.selectedTimestampRange.start - 1,
                        end: this.state.selectedTimestampRange.end - 1
                    },
                    fftSpectraData: {
                        spectra: [],
                        timestamps: []
                    }
                },() => {
                    this.getSpectra();
                })
            }
        }
    };

    handleSliderChange = (event, value) => {
        this.setState({
            selectedTimestampRange: {
                start: value[0],
                end: value[1]
            }
        })
    };

    onChangeRangeSlider = (event, newValue) => {
        let calibratedValue = this.calibrateData(newValue);
        this.setState({
            selectedTimestampRange: {
                start: calibratedValue[0],
                end: calibratedValue[1]
            },
            fftSpectraData: {
                spectra: [],
                timestamps: []
            }
        })

        this.getSpectra();
    };

    calibrateData = (value) => {
        if (value[0] === value[1]) {
            if (value[1] === this.state.timestamps.length - 1) {
                value[0] = value[0] - 1;
            }
            else {
                value[1] = value[1] + 1;
            }
        }
        return value;
    };

    getAvailableTimestamps = async () => {
        if (this.props.authentication.kustoToken && !this.state.loadingTimestamps) {

            this.setState({
                timestamps: [],
                loadingTimestamps: true,
                loadingSpectrum: true,
                loadingSpectra: true
            })

            var dateSize = new Array();
            var currentDate = moment(this.props.startTime);

            while (currentDate < this.props.endTime) {

                dateSize.push(moment(currentDate))
                currentDate = currentDate.add(7, 'days');

            }
            dateSize.push(this.props.endTime)


            for (var i = 0; i < dateSize.length; i++) {
                if (dateSize[i + 1]) {
                    await axios.post('api/spectra/timestamps/' + this.props.device + '/' + this.props.sensor,
                        {
                            'startTime': dateSize[i],
                            'endTime': dateSize[i + 1],
                        },
                        {
                            headers: {
                                'Authorization': 'Bearer ' + this.props.authentication.kustoToken
                            }
                        })
                        .then(res => {

                            this.setState({
                                loadingTimestamps: false,
                                fftValue: 0,
                                timestamps: [...this.state.timestamps, ...res.data.timestamp.sort((a, b) => moment(a) - moment(b))]
                            }, () => {
                                this.setState({
                                    'selectedTimestampRange': {
                                        'start': this.state.timestamps.length - 50 < 0 ? 0 : this.state.timestamps.length - 50,
                                        'end': this.state.timestamps.length
                                    }
                                }, () => {
                                    this.getSpectrum();

                                    if (this.state.spectrumMode !== 'FFT') {
                                        this.getSpectra();
                                    }
                                })
                            })
                        })
                        .catch(error => {

                            this.props.showNotification(error.message);

                            this.setState({
                                fftDataRevision: this.state.fftDataRevision + 1,
                                loadingTimestamps: false,
                                loadingSpectrum: false,
                                loadingSpectra: false
                            })
                        });
                }
            }


            // axios.post('api/spectra/timestamps/' + this.props.device + '/' + this.props.sensor,
            //     {
            //         'startTime': this.props.startTime,
            //         'endTime': this.props.endTime,
            //     },
            //     {
            //         headers: {
            //             'Authorization': 'Bearer ' + this.props.authentication.kustoToken
            //         }
            //     })
            //     .then(res => {

            //         this.setState({
            //             loadingTimestamps: false,
            //             fftValue: 0,
            //             timestamps: res.data.timestamp.sort((a, b) => moment(a) - moment(b))
            //         }, () => {
            //             this.setState({
            //                 'selectedTimestampRange': {
            //                     'start': this.state.timestamps.length - 50 < 0 ? 0 : this.state.timestamps.length - 50,
            //                     'end': this.state.timestamps.length
            //                 }
            //             }, () => {
            //                 this.getSpectrum();

            //                 if (this.state.spectrumMode !== 'FFT') {
            //                     this.getSpectra();
            //                 }
            //             })
            //         })
            //     })
            //     .catch(error => {

            //         this.props.showNotification(error.message);

            //         this.setState({
            //             fftDataRevision: this.state.fftDataRevision + 1,
            //             loadingTimestamps: false,
            //             loadingSpectrum: false,
            //             loadingSpectra: false
            //         })
            //     });
        }
    }

    onChangeFFTType = (type) => {
        this.setState({
            FFTType: type
        })
    };

    onChangeFFTMode = (mode) => {
        this.setState({
            spectrumMode: mode
        })
    };

    onSetReference = () => {
        // setReference(!reference);
        // setFFTDataRevision(fftDataRevision+1);
    };

    trendOnChange = (event, newValue) => {
        if (this.state.timestamps.length > 0) {
            this.setState({
                fftValue: newValue - 1
            })
        }

        // setReference(false);
        // setFFTValue(newValue);
        // setFFTDataRevision(fftDataRevision+1);
    };

    renderSelectedChart = () => {

        switch (this.state.spectrumMode) {
            case 'FFT':
                return (

                    <FFTSpectrum
                        optimumWidth={this.state.optimumWidth}
                        fftData={this.state.fftData}
                        FFTType={this.state.FFTType}
                        loadingTimestamps={this.state.loadingTimestamps}
                        loadingSpectrum={this.state.loadingSpectrum}
                        timestamps={this.state.timestamps}
                        fftValue={this.state.fftValue}
                        decreaseSliderValue={this.decreaseSliderValue}
                        trendOnChange={this.trendOnChange}
                        increaseSliderValue={this.increaseSliderValue} />

                );

            case 'SPECTROGRAM':


                return (

                    <FFTSpectrogram
                        fftSpectraData={this.state.fftSpectraData}
                        optimumWidth={this.state.optimumWidth}
                        selectedTimestampRange={this.state.selectedTimestampRange}
                        timestamps={this.state.timestamps}
                        FFTType={this.state.FFTType}
                        fftDataRevision={this.state.fftDataRevision}
                        uirevision={this.state.uirevision}
                        loadingSpectra={this.state.loadingSpectra}
                        loadingPercentage={this.state.loadingPercentage}
                        loadingTimestamps={this.state.loadingTimestamps}
                        cancelToken={this.state.cancelToken}
                        decreaseSliderRangeValue={this.decreaseSliderRangeValue}
                        increaseSliderRangeValue={this.increaseSliderRangeValue}
                        onChangeRangeSlider={this.onChangeRangeSlider}
                        handleSliderChange={this.handleSliderChange} />
                )


            case 'WATERFALL':
                return (<FFTWaterfall
                    fftSpectraData={this.state.fftSpectraData}
                    optimumWidth={this.state.optimumWidth}
                    selectedTimestampRange={this.state.selectedTimestampRange}
                    timestamps={this.state.timestamps}
                    FFTType={this.state.FFTType}
                    fftDataRevision={this.state.fftDataRevision}
                    uirevision={this.state.uirevision}
                    loadingSpectra={this.state.loadingSpectra}
                    loadingPercentage={this.state.loadingPercentage}
                    loadingTimestamps={this.state.loadingTimestamps}
                    cancelToken={this.state.cancelToken}
                    decreaseSliderRangeValue={this.decreaseSliderRangeValue}
                    increaseSliderRangeValue={this.increaseSliderRangeValue}
                    onChangeRangeSlider={this.onChangeRangeSlider}
                    handleSliderChange={this.handleSliderChange} />)

            default:
                return (<div id={"Empty"}></div>);
        }
    };



    render() {
        return <>
            <Grid container justifyContent='center' style={{ textAlign: 'center', marginTop: '20px' }}>
                <Grid item>
                    <h1 style={{ textTransform: 'none', textAlign: 'left' }}>FFT</h1>
                    <Paper className={'paper'} style={{ width: this.state.optimumWidth }} elevation={0}>
                        <Grid container justifyContent='flex-start' style={{ textAlign: 'center', width: this.state.optimumWidth + 'px' }}>
                            <Grid item>
                                <div style={{ marginTop: '40px' }}>
                                    <Button id={(this.state.spectrumMode === 'FFT') ? 'linde-button-focused' : 'LindeButton'} onClick={() => this.onChangeFFTMode('FFT')} style={{ marginLeft: (this.state.optimumWidth * 3 / 100) + 'px' }}>
                                        <span className='button-text'>FFT</span>
                                    </Button>
                                    <Button id={(this.state.spectrumMode === 'SPECTROGRAM') ? 'linde-button-focused' : 'LindeButton'} onClick={() => this.onChangeFFTMode('SPECTROGRAM')} style={{ marginLeft: '1px' }}>
                                        <span className='button-text'>Spectrogram</span>
                                    </Button>
                                    <Button id={(this.state.spectrumMode === 'WATERFALL') ? 'linde-button-focused' : 'LindeButton'} onClick={() => this.onChangeFFTMode('WATERFALL')} style={{ marginLeft: '1px' }}>
                                        <span className='button-text'>Waterfall</span>
                                    </Button>

                                    <Button id={(this.state.reference) ? 'linde-button-focused' : 'LindeButton'} disabled={(this.state.spectrumMode === 'FFT') ? false : true} onClick={() => this.onSetReference()} style={{ marginLeft: (this.state.optimumWidth * 1.5 / 100) + 'px' }}>
                                        <span className='button-text'>Set Reference</span>
                                    </Button>

                                    <Button id={(this.state.FFTType === FFTTypes[0]) ? 'linde-button-focused' : 'LindeButton'} onClick={() => this.onChangeFFTType(FFTTypes[0])} style={{ marginLeft: (this.state.optimumWidth * 1.5 / 100) + 'px' }}>
                                        <span className='button-text'>Peak</span>
                                    </Button>
                                    <Button id={(this.state.FFTType === FFTTypes[1]) ? 'linde-button-focused' : 'LindeButton'} onClick={() => this.onChangeFFTType(FFTTypes[1])} style={{ marginLeft: '1px' }}>
                                        <span className='button-text'>RMS</span>
                                    </Button>
                                    {/* <Button id={'LindeButton'}
                                        onClick={() => this.stopQuery()} style={{ marginLeft: (this.state.optimumWidth * 1.5 / 100) + 'px' }}>
                                        <span className='button-text'>Stop Query</span>
                                    </Button> */}
                                </div>
                            </Grid>
                            <br />
                            <Grid item style={{ textAlign: 'center' }}>
                                {this.renderSelectedChart()}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    }
}



let mapStateToProps = (state) => state;

let mapDispatchToProps = dispatch => {
    return {
        showNotification: function (msg, intent) {
            dispatch({
                type: SHOW_NOTIFICATION,
                message: msg,
                intent: intent
            });
        }
    };
};



// MonitoringObjectTable.propTypes = {
//     site: PropTypes.string,
//     device: PropTypes.string,
//     sensor: PropTypes.string
// };


export default connect(mapStateToProps, mapDispatchToProps)(MonitoringObjectSpectrumPanel);
