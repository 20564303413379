import React from 'react';
import { connect } from 'react-redux';
import { Grid, Paper } from '@material-ui/core';
import Plotly from 'plotly.js-dist-min';
import createPlotlyComponent from 'react-plotly.js/factory';
import moment from 'moment';
import Loader from 'components/Loader';
import { SHOW_NOTIFICATION } from 'reducers/notification/constants';

const Plot = createPlotlyComponent(Plotly);
class MonitoringObjectBarChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            lastRefresh: new moment(),
            MonitoringObjs: ['1X', '2X', '3X', '2LF', 'SUBX', 'MULTIX', 'ISO10816'],
            loading: false,
            edgeDataText: []
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.sensorsData?.length > 0) {
            // console.log(this.props.sensorsData);
            // console.log(this.props.sensorsData.map(a => a.value));
        }
    }

    render() {

        var respData = this.props.sensorsData
        var tooltips = []

        var barChartColor = []

        for (var sensor in respData) {
            tooltips.push('<b>' + respData[sensor]['object'] + ' : ' + respData[sensor]['value'] + ' mm/s <br /></b>' +
                'Warning Set Point : ' + respData[sensor]['warning'] + '<br />' +
                'Alarm Set Point : ' + respData[sensor]['alarm'] + '<br />');

            if (respData[sensor]['value'] > respData[sensor]['warning']) {
                if (respData[sensor]['value'] > respData[sensor]['alarm']) {
                    barChartColor.push('red')
                } else {

                    barChartColor.push('orange')
                }
            } else {

                barChartColor.push('#009b3c')

            }


        }



        return <div>
            {
                this.state.loading
                    ? <Loader title='Loading data' />
                    : (

                        <Grid container justifyContent='center' style={{ textAlign: 'center', marginTop: '80px' }}>
                            <Grid item>
                                <Paper id={'myBarChart'} className={'paper'} elevation={0} style={{ minWidth: '750px', minHeight: '450px' }}>
                                    <Plot
                                        useResizeHandler
                                        data={[
                                            {
                                                x: this.props.sensorsData.map(a => a.object),
                                                name: this.props.sensorsData.map(a => a.object),
                                                y: this.props.sensorsData.map(a => a.value),
                                                type: 'bar',
                                                marker: { color: barChartColor },
                                                text: tooltips,
                                                hoverinfo: 'text',
                                                textposition: 'none'
                                            }
                                        ]}
                                        layout={{
                                            bargap: 0.05,
                                            autosize: true,
                                            xaxis: {
                                                type: 'string',
                                                title: 'Monitoring Objects',
                                                color: '#005591'
                                            },
                                            yaxis: {
                                                type: 'string',
                                                title: 'mm/s',
                                                color: '#005591'
                                            },
                                            plot_bgcolor: 'white',
                                            paper_bgcolor: 'white',
                                        }}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>

                    )
            }
        </div>
    }
}

let mapStateToProps = (state) => state;

let mapDispatchToProps = dispatch => {
    return {
        showNotification: function (msg, intent) {
            dispatch({
                type: SHOW_NOTIFICATION,
                message: msg,
                intent: intent
            });
        }
    };
};

// MonitoringObjectTable.propTypes = {
//     site: PropTypes.string,
//     device: PropTypes.string,
//     sensor: PropTypes.string
// };

export default connect(mapStateToProps, mapDispatchToProps)(MonitoringObjectBarChart);
