
import { Component } from 'react'
import Header from 'pages/_layout/Header';
import Footer from 'pages/_layout/Footer';
import { createTheme, ThemeProvider, CssBaseline, Grid } from '@material-ui/core';

// Styles & Custom Fonts
import LindeDaxReg from 'assets/font/LindeDaxRegWeb.woff';




const theme = createTheme({
    typography: {
        fontFamily: 'LindeDax, Arial, sans-serif',
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [{
                    fontFamily: 'LindeDax',
                    fontStyle: 'normal',
                    fontDisplay: 'swap',
                    fontWeight: 400,
                    src: `
                    local('LindeDax'),
                    url(${LindeDaxReg}) format('woff2')
                  `,
                    unicodeRange:
                        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
                }],
            },
        },
    },
});


export default class LayoutWrapper extends Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Header />
                <Grid container className="page-container">
                    <Grid container className="grid-container">
                        <Grid item xs={12} className="content-section">
                            {this.props.children}
                        </Grid>
                    </Grid>
                </Grid>
                <Footer />
            </ThemeProvider>
        )
    }
}