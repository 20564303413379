import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import HomeView from 'pages/Homepage';
import LegalNotice from 'pages/Policy/LegalNotice';
import TermsCondition from 'pages/Policy/TermsCondition';
import PrivacyPolicy from 'pages/Policy/PrivacyPolicy';
import Device from 'pages/Dashboard/Device';
// import Template from 'pages/Template';
import Sensor from 'pages/Dashboard/Sensor';
import Loader from 'components/Loader';
import Manual from 'pages/Manual';

import Login from 'pages/Login';

import LayoutWrapper from 'pages/_layout/LayoutWrapper';


const Routes = () => {
    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                <Route path="/login" exact component={Login} />
                <LayoutWrapper>

                    <Route component={({ match }) =>
                        <div>
                            <Route path="/" exact component={HomeView} />
                            <Route path="/token*" component={HomeView} />
                            <Route path="/id_token=*" component={HomeView} />
                            <Route path="/Policy/Legal-Notice" exact component={LegalNotice} />
                            <Route path="/Policy/Terms-Conditions" exact component={TermsCondition} />
                            <Route path="/Policy/Privacy-Policy" exact component={PrivacyPolicy} />
                            <Route path="/Overview/:site/:device" exact component={Device} />
                            <Route path="/Overview/:site/:device/:sensor" exact component={Sensor} />
                            <Route path="/Manual" exact component={Manual} />
                            {/* <Route path="/Template" exact component={Template} /> */}
                        </div>
                    } />

                </LayoutWrapper>

            </Switch>
        </Suspense>
    );
};

export default Routes;
