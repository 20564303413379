import React from 'react';


class MotorView extends React.Component {
    constructor(props) {
        super(props)
        this.state= {
            color : "#E0E0E0;",
            dur  : "0.1s",
            rev : 0 
        }
    }

    componentDidMount(){
        if(this.state.rev === 0 ){
            switch (this.props.alarmLevel) {
                case "NORMAL":
                    this.setState({
                        color: "#008000;",
                        dur: "0.1s"
                    })
                    break;
    
                case "WARNING":
    
                    this.setState({
                        color: "yellow;#FFC55C;yellow",
                        dur: "1.5s"
                    })
                    break;
    
                case "ALARM":
                    this.setState({
                        color: "#E0E0E0;#FF0000;#E0E0E0",
                        dur: "0.2s"
                    })
                    break;
    
                default:
                    this.setState({
                        color: "#E0E0E0;",
                        dur: "0.1s"
                    })
            }
        }
    }

    componentDidUpdate(preProps){
        if(preProps.alarmLevel !== this.props.alarmLevel){
            switch (this.props.alarmLevel.toUpperCase()) {
                case "NORMAL":
                    this.setState({
                        color: "#008000;",
                        dur: "0.1s"
                    })
                    break;
    
                case "WARNING":
    
                    this.setState({
                        color: "yellow;#FFC55C;yellow",
                        dur: "1.5s"
                    })
                    break;
    
                case "ALARM":
                    this.setState({
                        color: "#E0E0E0;#FF0000;#E0E0E0",
                        dur: "0.2s"
                    })
                    break;
    
                default:
                    this.setState({
                        color: "#E0E0E0;",
                        dur: "0.1s"
                    })
            }
        }
    }

    render() {

        return (

            <div style={{ minWidth: '350px', width: '100%', margin: 'auto' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 560.289 283.33">
                        <g stroke="#000" fill="none">
                            <animate
                                attributeType="XML"
                                attributeName="stroke"
                                values={this.state.color}
                                dur={this.state.dur}
                                repeatCount="indefinite" />
                            <path
                                d="M40.79 1h41.417v281.33H40.79S12.103 215.472 12 130.3C11.898 45.13 40.792 1 40.792 1z"
                                strokeWidth="2" />
                            <path
                                d="M82.2 25.743h359.62s30.242 42.738 29.8 111.2c-.44 68.467-29.8 120.63-29.8 120.63H82.2z"
                                strokeWidth="1.998" />
                            <g strokeWidth="1.703">
                                <path d="M114.78 46.658h185.88M114.78 236.658h185.88M138.78 90.658h185.88M138.78 192.658h185.88M170.78 138.658h185.88" />
                            </g>
                            <g strokeWidth="1.772">
                                <path d="M21.342 50.502L.886 49.997v183.34l23.486-.505M468.525 174.871h90.879v-73.985h-90.665 0" />
                            </g>
                        </g>
                    </svg>
            </div>


        );
    }
}

export default MotorView;

