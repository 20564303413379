import React from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";

class ResetZoom extends React.Component {
    helpDiv;

    createButtonControl() {
        const MapHelp = L.Control.extend({
            onAdd: (map) => {
                const helpDiv = L.DomUtil.create('Button', 'resetzoom');
                this.helpDiv = helpDiv;
                helpDiv.innerHTML = `<Button class="${"MuiButtonBase-root MuiButton-root MuiButton-text"} type="${"button"}"">Reset</Button>`;
                helpDiv.addEventListener("click", () => {
                    map.setView(map.options.center, map.options.zoom);;
                });
                return helpDiv;
            }
        });
        return new MapHelp({ position: "topright" });
    }

    componentDidMount() {
        const { map } = this.props;
        const control = this.createButtonControl();
        control.addTo(map);
    }

    componentWillUnmount() {
        this.helpDiv.remove();
    }

    render() {
        return null;
    }
}

function withMap(Component) {
    return function WrappedComponent(props) {
        const map = useMap();
        return <Component {...props} map={map} />;
    };
}

export default withMap(ResetZoom);
