import React from 'react';
import { connect } from 'react-redux';
import { Grid, IconButton, Slider } from '@material-ui/core';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import moment from 'moment';
import { SHOW_NOTIFICATION } from 'reducers/notification/constants';
import Waterfall from 'components/Chart/Waterfall';
import Loader from 'components/Loader';


const FFTTypes = ['Peak', 'RMS'];

class FFTWaterfall extends React.Component {
    
    render() {

        let w_xData = [];
        let w_freqStep = 0;
        let waterfallScaleText = [];
        let w_scaleZData = [];
        let currentTickValues = []
        let currentTickText = []

        if (this.props.fftSpectraData.spectra.length > 0) {


            for (let i = 0; i < this.props.fftSpectraData.spectra[0].length; i++) {
                w_xData.push((i * 1.52587891) + w_freqStep);
            }


            if (this.props.FFTType === FFTTypes[0]) {
                w_scaleZData = this.props.fftSpectraData.spectra.map((element) => {
                    return element.map((inner) => {
                        return Math.sqrt(inner) * 1000;
                    });
                });
            }
            else {
                w_scaleZData = this.props.fftSpectraData.spectra.map((element) => {
                    return element.map((inner) => {
                        return Math.sqrt(inner) * 707.106781186547524;
                    });
                });
            }

            let workaroundScaleZData = w_scaleZData;
            workaroundScaleZData.push(workaroundScaleZData[workaroundScaleZData.length - 1]);


            waterfallScaleText = this.props.fftSpectraData.timestamps.map((xi, i) => w_xData.map((yi, j) => `
            id: ${i} <br>
            timestamp: ${moment(xi).format(('DD.MM.YYYY HH:mm:ss'))} <br>
            frequency: ${yi.toFixed(2)} Hz <br>
            amplitude: ${w_scaleZData[i][j].toFixed(4)} mm/s<br>
            `))

            for (let i = 0; i < this.props.fftSpectraData.timestamps.length / 10; i++) {
                currentTickValues.push(i * 10);
                currentTickText.push(moment(this.props.fftSpectraData.timestamps[i * 10]).format('DD.MM.YYYY HH:mm:ss'));
            }
        }


        return (
            <>
            <div style={{ marginTop: '40px', width: this.props.optimumWidth, height: '70px' }}>
                {
                    (this.props.loadingTimestamps) ? 
                        <span className={'slider-text'}> Loading </span>
                    :
                        <span className={'slider-text'}>
                            FFT Spectra: {this.props.selectedTimestampRange.start + 1} - {this.props.selectedTimestampRange.end + 1} /
                            Time: {moment(this.props.timestamps[this.props.selectedTimestampRange.start]).format('DD.MM.YYYY HH:mm:ss')} -
                            {moment(this.props.timestamps[this.props.selectedTimestampRange.end]).format('DD.MM.YYYY HH:mm:ss')}
                        </span>
                }
                <Grid container justifyContent='space-between' style={{ textAlign: 'center' }}>
                    <Grid item style={{ marginLeft: '60px' }}>
                        <IconButton id='LindeButton' onClick={() => this.props.decreaseSliderRangeValue()}><NavigateBefore /></IconButton>
                    </Grid>
                    <Grid item>
                        <Slider
                            // key={'Spectrogram' + spectrogramKey}
                            value={[this.props.selectedTimestampRange.start, this.props.selectedTimestampRange.end]}
                            onChange={this.props.handleSliderChange}
                            onChangeCommitted={this.props.onChangeRangeSlider}
                            valueLabelDisplay='auto'
                            step={1}
                            min={0}
                            max={this.props.timestamps.length}
                            marks={false}
                            style={{ width: (this.props.optimumWidth * 80 / 100) + 'px', color: '#005591' }}
                        // disabled={isLoading}
                        />
                    </Grid>
                    <Grid item style={{ marginRight: '60px' }}>
                        <IconButton id='LindeButton' onClick={() => this.props.increaseSliderRangeValue()}><NavigateNext /></IconButton>
                    </Grid>
                </Grid>
            </div>


            <div style={{ height: '611px', position: 'relative' }}>
                {
                    (this.props.loadingSpectra) ? 
                        <Loader title={`Generating Data (${this.props.loadingPercentage}%)`} cancelToken={this.props.cancelToken} />
                    :   <></>
                }
                {
                    (this.props.fftSpectraData.spectra.length > 0) ?

                        <Waterfall
                            useResizeHandler
                            data={[
                                {
                                    x: w_xData,
                                    z: w_scaleZData,
                                    // y: this.props.fftSpectraData.timestamps,
                                    text: waterfallScaleText,
                                    hoverinfo: 'text',
                                    type: 'surface',
                                    colorscale: 'Jet',
                                    cmax: (this.props.FFTType.toUpperCase() === 'PEAK') ? 4.5 : (4.5 * 0.707106781186547524),
                                    cmin: 0,
                                    showscale: true,
                                    colorbar: {
                                        title: {
                                            text: '',
                                            font: {
                                                color: '#005591'
                                            }
                                        },
                                        titleside: 'right',
                                        tickfont: {
                                            color: '#005591'
                                        }
                                    }
                                }
                            ]}
                            layout={{
                                autosize: true,
                                datarevision: this.props.fftDataRevision,
                                uirevision: this.props.uirevision,
                                height: 600,
                                width: (this.props.optimumWidth * 95 / 100),
                                scene: {
                                    aspectmode: 'manual',
                                    aspectratio: {
                                        x: 1, y: 0.5, z: 0.5,
                                    },
                                    xaxis: {
                                        nticks: 9,
                                        title: 'f in Hz',
                                        //rise range to fit time stamps
                                        range: [0, 1500],
                                        color: '#005591'
                                    },
                                    yaxis: {
                                        ticktext: currentTickText,
                                        tickvals: currentTickValues,
                                        color: '#005591'
                                    },
                                    zaxis: {
                                        nticks: 10,
                                        title: 'mm/s (' + this.props.FFTType + ')',
                                        color: '#005591'
                                    },
                                    camera: { eye: { x: 0.4, y: -1, z: 0.4 } }
                                },
                                margin: {
                                    l: 65,
                                    r: 50,
                                    b: 65,
                                    t: 90,
                                },
                                opacity: 0.5,
                                hoverlabel: {
                                    bgcolor: '#B2E2F6',
                                    bordercolor: '#005591',
                                    font: {
                                        color: '#005591'
                                    }
                                }
                            }}
                            config={{
                                toImageButtonOptions: {
                                    format: 'png', // one of png, svg, jpeg, webp
                                    filename: 'rme',
                                    //width: 1024,
                                    height: 600,
                                    scale: 1.5 // Multiply title/legend/axis/canvas sizes by this factor
                                }
                            }}
                        />
                        : <Waterfall useResizeHandler
                            data={[
                                {
                                    x: [],
                                    z: [],
                                    type: 'surface',
                                    colorscale: 'Jet',
                                    cmax: (this.props.FFTType.toUpperCase() === 'PEAK') ? 4.5 : (4.5 * 0.707106781186547524),
                                    cmin: 0,
                                    showscale: true,
                                }
                            ]}
                            layout={{
                                datarevision: 0,
                                uirevision: 0,
                                height: 600,
                                width: (this.props.optimumWidth * 95 / 100),
                                scene: {
                                    aspectmode: 'manual',
                                    aspectratio: {
                                        x: 1, y: 0.5, z: 0.5,
                                    },
                                    xaxis: {
                                        nticks: 9,
                                        title: 'f in Hz',
                                        range: [0, 1200],
                                        color: '#005591'
                                    },
                                    zaxis: {
                                        nticks: 1,
                                        title: 'mm/s (' + this.props.FFTType + ')',
                                        color: '#005591'
                                    },
                                    camera: { eye: { x: 0.4, y: -1, z: 0.4 } }
                                },
                                margin: {
                                    l: 65,
                                    r: 50,
                                    b: 65,
                                    t: 90,
                                },
                                opacity: 0.5,
                                hoverlabel: {
                                    bgcolor: '#B2E2F6',
                                    bordercolor: '#005591',
                                    font: {
                                        color: '#005591'
                                    }
                                }
                            }}
                            config={{
                                toImageButtonOptions: {
                                    format: 'png', // one of png, svg, jpeg, webp
                                    filename: 'rme',
                                    //width: 1024,
                                    height: 600,
                                    scale: 1.5 // Multiply title/legend/axis/canvas sizes by this factor
                                }
                            }} />
                }

            </div>

            {/* <WaterfallChart optimumWidth={this.props.optimumWidth} unit={this.props.FFTType} /> */}
        </>
        );
    }
}



let mapStateToProps = (props) => props;

let mapDispatchToProps = dispatch => {
    return {
        showNotification: function (msg, intent) {
            dispatch({
                type: SHOW_NOTIFICATION,
                message: msg,
                intent: intent
            });
        }
    };
};



export default connect(mapStateToProps, mapDispatchToProps)(FFTWaterfall);
