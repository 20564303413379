import React from 'react';
import { connect } from 'react-redux';
import { Grid, IconButton, Slider } from '@material-ui/core';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import moment from 'moment';
import Loader from 'components/Loader';
import { SHOW_NOTIFICATION } from 'reducers/notification/constants';
import Spectrogram from 'components/Chart/Spectrogram';


const FFTTypes = ['Peak', 'RMS'];

class FFTSpectrogram extends React.Component {
    render() {

        let w_xData = [];
        let w_freqStep = 0;
        let waterfallScaleText = [];
        let w_scaleZData = [];
        let currentTickValues = []
        let currentTickText = []

        if (this.props.fftSpectraData.spectra.length > 0) {


            for (let i = 0; i < this.props.fftSpectraData.spectra[0].length; i++) {
                w_xData.push((i * 1.52587891) + w_freqStep);
            }


            if (this.props.FFTType === FFTTypes[0]) {
                w_scaleZData = this.props.fftSpectraData.spectra.map((element) => {
                    return element.map((inner) => {
                        return Math.sqrt(inner) * 1000;
                    });
                });
            }
            else {
                w_scaleZData = this.props.fftSpectraData.spectra.map((element) => {
                    return element.map((inner) => {
                        return Math.sqrt(inner) * 707.106781186547524;
                    });
                });
            }

            let workaroundScaleZData = w_scaleZData;
            workaroundScaleZData.push(workaroundScaleZData[workaroundScaleZData.length - 1]);


            waterfallScaleText = this.props.fftSpectraData.timestamps.map((xi, i) => w_xData.map((yi, j) => `
            id: ${i} <br>
            timestamp: ${moment(xi).format(('DD.MM.YYYY HH:mm:ss'))} <br>
            frequency: ${yi.toFixed(2)} Hz <br>
            amplitude: ${w_scaleZData[i][j].toFixed(4)} mm/s<br>
            `))

            for (let i = 0; i < this.props.fftSpectraData.timestamps.length / 10; i++) {
                currentTickValues.push(i * 10);
                currentTickText.push(moment(this.props.fftSpectraData.timestamps[i * 10]).format('DD.MM.YYYY HH:mm:ss'));
            }
        }

        return (
            <>

                <div style={{ marginTop: '40px', width: this.props.optimumWidth, height: '70px' }}>
                {
                    (this.props.loadingTimestamps) ? 
                        <span className={'slider-text'}> Loading </span>
                    :
                        <span className={'slider-text'}>
                            FFT Spectra: {this.props.selectedTimestampRange.start + 1} - {this.props.selectedTimestampRange.end + 1} /
                            Time: {moment(this.props.timestamps[this.props.selectedTimestampRange.start]).format('DD.MM.YYYY HH:mm:ss')} -
                            {moment(this.props.timestamps[this.props.selectedTimestampRange.end]).format('DD.MM.YYYY HH:mm:ss')}
                        </span>
                }
                    <Grid container justifyContent='space-between' style={{ textAlign: 'center' }}>
                        <Grid item style={{ marginLeft: '60px' }}>
                            <IconButton id='LindeButton' onClick={() => this.props.decreaseSliderRangeValue()}><NavigateBefore /></IconButton>
                        </Grid>
                        <Grid item>
                            <Slider
                                // key={'Spectrogram' + spectrogramKey}
                                value={[this.props.selectedTimestampRange.start, this.props.selectedTimestampRange.end]}
                                onChange={this.props.handleSliderChange}
                                onChangeCommitted={this.props.onChangeRangeSlider}
                                valueLabelDisplay='auto'
                                step={1}
                                min={0}
                                max={this.props.timestamps.length}
                                marks={false}
                                style={{ width: (this.props.optimumWidth * 80 / 100) + 'px', color: '#005591' }}
                            // disabled={isLoading}
                            />
                        </Grid>
                        <Grid item style={{ marginRight: '60px' }}>
                            <IconButton id='LindeButton' onClick={() => this.props.increaseSliderRangeValue()}><NavigateNext /></IconButton>
                        </Grid>
                    </Grid>
                </div>

                <div style={{ height: '611px', position: 'relative' }}>
                    {
                        (this.props.loadingSpectra) ? 
                            <Loader title={`Generating Data (${this.props.loadingPercentage}%)`} cancelToken={this.props.cancelToken} />
                        :   <></>
                    }

                    <Spectrogram
                        data={[
                            {
                                x: w_xData,
                                z: w_scaleZData,
                                type: 'heatmapgl',
                                colorscale: 'Jet',
                                hoverinfo: 'text',
                                text: waterfallScaleText,
                                zmin: 0,
                                zmax: (this.props.FFTType.toUpperCase() === 'PEAK') ? 4.5 : 3.1819805153394642,
                                colorbar: {
                                    title: {
                                        text: 'mm/s (' + this.props.FFTType + ')',
                                        font: {
                                            color: '#005591'
                                        }
                                    },
                                    titleside: 'right',
                                    tickfont: {
                                        color: '#005591'
                                    }
                                }
                            }
                        ]}
                        layout={{
                            autosize: true,
                            datarevision: this.props.fftDataRevision,
                            uirevision: this.props.uirevision,
                            xaxis: { title: 'f in Hz', color: '#005591' },
                            yaxis: { title: 't', color: '#005591', showticklabels: false },
                            width: (this.props.optimumWidth * 95 / 100),
                            height: 600,
                            hoverlabel: {
                                bgcolor: '#B2E2F6',
                                bordercolor: '#005591',
                                font: {
                                    color: '#005591'
                                }
                            }
                        }}
                    />
                </div>
            </>
        );
    }
}



let mapStateToProps = (props) => props;

let mapDispatchToProps = dispatch => {
    return {
        showNotification: function (msg, intent) {
            dispatch({
                type: SHOW_NOTIFICATION,
                message: msg,
                intent: intent
            });
        }
    };
};



export default connect(mapStateToProps, mapDispatchToProps)(FFTSpectrogram);
