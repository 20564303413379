// React Modules
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';

// Styles & Custom Fonts
import 'App.scss';

// Pages
import Routes from 'routes';
import { Provider } from 'react-redux';
import { store } from 'reducers';

import Alert from 'components/Notification'
import { API_Endpoint } from 'config/settings';
import axios from 'axios'

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = API_Endpoint;
}
axios.defaults.timeout = 0;
axios.defaults.headers.post['Content-Type'] = 'application/json';



document.title = 'EDAS';


ReactDOM.render(
    <Provider store={store}>
        <Alert />
        <HashRouter>

            <Routes />
        </HashRouter>
    </Provider>
    , document.getElementById('root')
);


