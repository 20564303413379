import axios from 'axios';

export const API_Endpoint = process.env.REACT_APP_API_Endpoint

export const apiEndpoint = {
    Alarm: {
        GetAlarmByDeviceId: '/api/Alarm'
    },
    Comment: {
        CreateComment: '/api/Comment',
        GetCommentsByDeviceId: '/api/Comment'
    },
    Threshold: {
        GetThresholdByDevice: '/api/Threshold'
    },
    Trend: {
        GetTrends: '/api/Trend'
    },
    Report: {
        GenerateReport: '/report',
        GetReportByDeviceId: '/api/Report'
    },
    User: {
        CheckUser: '/api/User'
    }
}

export const getNewCancelToken = () => {
    return axios.CancelToken.source();
};