import { UPDATE_DEVICES, UPDATE_MONITOROBJ, UPDATE_SITES, COMPLETED, UPDATE_MONITOROBJALL, CHANGE_QUERY_STATUS } from './constants';

export const updateSites = (payload) => ({
    type: UPDATE_SITES,
    payload,
});

export const updateDevices = (payload) => ({
    type: UPDATE_DEVICES,
    payload,
});
export const updateMonitoringObj = (site, edge, sensor, monitoringobj, monitoringobjstatus, monitoringobjVal) => ({
    type: UPDATE_MONITOROBJ,
    site, edge, sensor, monitoringobj, monitoringobjstatus, monitoringobjVal
});


export const updateMonitoringObjAll = (payload) => ({
    type: UPDATE_MONITOROBJALL,
    payload
});




export const changeQueryStatus = () => ({
    type: CHANGE_QUERY_STATUS
})



export const completed = () => ({
    type: COMPLETED
})
