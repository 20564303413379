import { UserAgentApplication } from "msal";

export const msalApp = new UserAgentApplication({
    auth: {
        tenantId: "1562f007-09a4-4fcb-936b-e79246571fc7",
        clientId: "bd5012c2-9c12-44b8-aa02-7d3fbf9cf132",
        authority: "https://login.microsoftonline.com/lindegroup.onmicrosoft.com",
        validateAuthority: true,
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    },
});


export const resourceScope = "api://bd5012c2-9c12-44b8-aa02-7d3fbf9cf132/access_as_user"

export const loginRequest = {
    scopes: [resourceScope, "openid", "profile"]
}

export const tokenRequest = {
    scopes: [resourceScope, "offline_access"]
}


export const kustoRequest = {
    scopes: ["https://help.kusto.windows.net/user_impersonation"]
};

export const graphRequest = {
    scopes: ["openid", "profile", "User.Read", "Group.Read.All", "User.ReadBasic.All"],
}

export const edgeCentralRequest = {
    scopes: ["api://ded4e8e2-7e25-4903-a9ca-b1ab80c64449/user_impersonation"]
    // edgeCentralScope: "api://ded4e8e2-7e25-4903-a9ca-b1ab80c64449/.default"
}
