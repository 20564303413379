import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Grid, IconButton, Fab, Badge, List, ListItem, Box, Drawer, InputBase } from '@material-ui/core';
import { CommentSharp, Close,Send } from '@material-ui/icons';
import Loader from 'components/Loader';
import colors from 'assets/styles/_colors.scss';
import axios from 'axios';
import { apiEndpoint } from 'config/settings';
import { useSelector } from 'react-redux';


const Comment = props => {

    const { deviceId } = props;
    const authentication = useSelector(x => x.authentication);

    useEffect(() => {
        getComments();
    }, [deviceId]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Loading and error
     */
    const isLoading = false;

    /**
     * comment drawer
     */
    const [openComment, setOpenComment] = useState(false);
    const openCommentDrawer = () => {
        setOpenComment(!openComment);
    };

    /**
     * Data
     */
    const [comments, setComments] = useState([]);
    const [commentData, setCommentData] = useState([]);

    /**
     * render comment list
     */
    const getComments = async () => {
        if(deviceId){
            
        let headers = {
            Authorization: `Bearer ${authentication.accessToken}`
        };
    
        await axios.get(`${apiEndpoint.Comment.GetCommentsByDeviceId}/${deviceId}`, {headers})
            .then(data => {
                data = data.data;
                let messageList = [];

                for (var i=0; i < data.length; i++) {
                    messageList.push(
                        <ListItem key={`comment${i}`} style={{ borderBottom: '1px solid #005591' }}>
                            <Comment
                                author={data[i].user?.name}
                                msg={data[i].text}
                                timestamp={moment(data[i].dateCreated).format('YYYY-MM-DD HH:mm:ss A')}
                            />
                        </ListItem>
                    );
                }

                setCommentData(messageList);
            })
            .catch(error => {
                console.log('Fail to get Comment Data > ', error);
                return ([]);
            });

            
        }
    };

    /**
     * comment list
     */
    const Comment = ({ author, timestamp, msg }) => (
        <Grid container>
            <Box px={2} py={2} boxShadow={3} style={{ width: '100%', boxShadow: 'none', padding: '16px 0px' }}>
                <span style={{ fontSize: '18px', color: '#005591' }} className='LindeDaxMed'>{author}</span>
                <p style={{ fontSize: '14px', color: '#005591', textAlign: 'justify' }}>{msg}</p>
                <p style={{ fontSize: '0.8em', textAlign: 'right', color: '#005591' }}>{timestamp}</p>
            </Box>
        </Grid>
    );
 
    // TO REMOVE: There is no plan to handle "message seen" feature yet
    const showDot = () => {
        if (false) {
            return (
                <Badge variant='dot' overlap='circular' color='secondary'>
                    <CommentSharp />
                </Badge>
            );
        }
        return <CommentSharp />;
    };

    const onChangeComment = (text) => {
        setComments(text);
        
        if (text.length > 0) {
            setDisableSubmitButton(false);
        }
        else {
            setDisableSubmitButton(true);
        }
    };

    /**
     * button event
     */
    const [disableSubmitButton, setDisableSubmitButton] = useState(true);
    const disableInput = false;

    const onSubmit = () => {
        // POST TO SAVE COMMENT
        let config = {
            headers: { Authorization: `Bearer ${authentication.accessToken}`}
        };

        let body = {  
            deviceId: deviceId,
            dateCreated: moment().format('YYYY-MM-DDTHH:mm:ss'),
            userId: authentication?.idToken?.oid,
            text: comments
        };

        axios.post(`${apiEndpoint.Comment.CreateComment}/${deviceId}`, body, config).then(data => {
            getComments();
        }).catch(error => {
            console.log('Fail to post comment > ', error);
        });
    };

    return (
        <>
            {/* Comment */}
            <Fab
                color='secondary'
                aria-label='comment'
                style={{
                    position: 'fixed',
                    bottom: '60px',
                    right: '50px',
                    zIndex: 11,
                }}
                className='comment-button'
                onClick={openCommentDrawer}
            >
                {showDot()}
            </Fab>

            <Drawer
                variant='temporary'
                open={openComment}
                anchor='right'
                onClose={() => { setOpenComment(false); }}
                style={{
                    flexShrink: 0,
                    zIndex: 12,
                }}
            >
                <section style={{
                    width: '100%',
                    height: '100%',
                    background: colors.lindeBlueGray10,
                }}>
                    {/* {isError.show
                        ? <ErrorMsg msg={isError.msg} onRefreshClick={onRefreshClick} />
                        : null
                    } */}
                    <Box mt={2} style={{ borderBottom: '2px solid #005591' }}>
                        <h3 className='LindeDaxMed' style={{ color: '#005591', textTransform: 'none', fontSize: '24px', marginLeft: '8px' }}>Comments</h3>
                        <IconButton style={{ position: 'fixed', right: '0px', top: '7px' }} onClick={() => setOpenComment(false)}>
                            <Close style={{ color: '#005591' }} />
                        </IconButton>
                    </Box>
                    {/* <Box
                        position='fixed'
                        right={0}
                        top={0}
                        style={{ height: '20px', zIndex: 12 }}
                    >
                        <IconButton onClick={() => setOpenComment(false)}>
                            <Close style={{ color: '#005591' }} />
                        </IconButton>
                    </Box> */}
                    <Box style={{
                        width: '370px',
                        height: 'calc(100% - 150px)',
                        overflow: 'auto'
                    }}>
                        {isLoading
                            ? <Loader title='Getting comments' />
                            : (
                                <List>
                                    {commentData}
                                </List>
                            )
                        }
                    </Box>
                    <Box
                        style={{
                            position: 'fixed',
                            bottom: '0',
                            width: '370px',
                            height: '80px',
                            display: 'flex',
                            alignItems: 'center',
                            padding: '2px 4px',
                            borderTop: '1px solid #005591',
                        }}>
                        <InputBase
                            multiline
                            minRows={1}
                            maxRows={3}
                            value={comments}
                            placeholder='Please comment...'
                            onChange={e => onChangeComment(e.target.value)}
                            style={{ marginLeft: '10px', flex: 1 }}
                            disabled={disableInput}
                        />
                        <IconButton
                            type='submit'
                            style={{ padding: '10px' }}
                            className='send-comment-button'
                            onClick={onSubmit}
                            disabled={disableSubmitButton}
                        >
                            <Send />
                        </IconButton>
                    </Box>
                </section>
            </Drawer>
        </>
    );
};

export default Comment;