import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "react-leaflet";

import { LoadingButton } from '@mui/lab'
import { Stack } from '@mui/material'



const TooltipTemplate = ({ device,site, type, connectivity, sensors }) => {

    var normalChipStyle = { width: "100%", backgroundColor: 'green', color: 'white' }
    var warningChipStyle = { width: "100%", backgroundColor: 'yellow', color: 'black' }
    var alarmChipStyle = { width: "100%", backgroundColor: 'red', color: 'black' }
    var noneStyle = { width: "100%" }

    var loadButtons = []


    for (var sensor in sensors) {
        var style = noneStyle;


        if ([...new Set(Object.values(sensors[sensor]).map(x=> x.status))].includes('Alarm')) {
            style = alarmChipStyle
        } else if ([...new Set(Object.values(sensors[sensor]).map(x=> x.status))].includes('Warning')) {
            style = warningChipStyle
        } else if ([...new Set(Object.values(sensors[sensor]).map(x=> x.status))].includes('Normal')) {
            style = normalChipStyle
        }


        

        loadButtons.push(
            <LoadingButton key={sensor} size="small" style={style} >
                {sensor}
            </LoadingButton>
        )
    }

    var imgPath = `${process.env.PUBLIC_URL}/img/edge_device/Nexcom.png`
    if (type === 'robogate') {
        imgPath = `${process.env.PUBLIC_URL}/img/edge_device/Robogate.png`
    } else if (type === 'moxa') {
        imgPath = `${process.env.PUBLIC_URL}/img/edge_device/Moxa-UC8200.png`
    }

    return <Tooltip sticky>
        <div style={{ textAlign: "center" }}>
            <img src={imgPath} alt="Not found" style={{maxWidth:'80px', maxHeight:'100px'}} />
        </div>
        <strong>{device}</strong> <br />
        Site : {site} <br />
        Connectivity : {connectivity} <br />
        <Stack direction="row" spacing={1} marginTop="10px">
            {loadButtons[0]}
            {loadButtons[1]}
        </Stack>
        <Stack direction="row" spacing={1} marginTop="10px">
            {loadButtons[2]}
            {loadButtons[3]}
        </Stack>
    </Tooltip>;
};

TooltipTemplate.propTypes = {
    device: PropTypes.string.isRequired
};

export default TooltipTemplate;
