import React, { useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import './index.scss';
import { updateAccount, updateError, updateToken } from 'reducers/authentication/actions'
import { connect } from 'react-redux';
import AuthProvider from 'components/authentication/utils/authProvier';

const MicrosoftIcon = () => {
    return (
        <svg
            style={{ width: '30px', height: '30px', marginRight: '1em' }}
            xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25'>
            <path fill='#f3f3f3' d='M0 0h23v23H0z' />
            <path fill='#f35325' d='M1 1h10v10H1z' />
            <path fill='#81bc06' d='M12 1h10v10H12z' />
            <path fill='#05a6f0' d='M1 12h10v10H1z' />
            <path fill='#ffba08' d='M12 12h10v10H12z' />
        </svg>
    );
};

const LoginPage = props => {

    const handleLogin = () => {
        props.onSignIn().then(() => {
            if (props.account) {
                console.log(props.account)
                props.updateAccount(props.account);
            } else {
                if (props.error) {
                    props.updateError(props.error);
                } else {
                    props.updateError({ errorMessage: 'Sign-in failed. Please try again.' });
                }
            }
        });
    };

    useEffect(() => {
        document.title = 'EDAS - Login';
    }, []);

    
    if (props.account) {
        window.location.hash = '/'
        // return <Redirect to={window.location = '/#/'} />;
    }



    return (
        <Box className='landing'>
            <Box className='landing__btn-bg'>
                <Button
                    className='landing__btn'
                    onClick={handleLogin}>
                    <MicrosoftIcon />
                    <span>Login with Microsoft Account</span>
                </Button>
            </Box>
        </Box >
    );
};

const mapStateToProps = (state) => state;

let mapDispatchToProps = dispatch => {
    return {
        updateAccount: (account) => {
            dispatch(updateAccount(account))
        },
        updateError: (error) => {
            dispatch(updateError(error))
        },
        updateToken: (token) => {
            dispatch(updateToken(token))
        },
        updateDeviceStatus: function (deviceName, devicestatus) {
            dispatch({
                type: "UPDATE_DEVICE_ALARM",
                device: deviceName,
                status: devicestatus
            });
        },
        showNotification: function (msg, intent) {
            dispatch({
                type: "SHOW_NOTIFICATION",
                message: msg,
                intent: intent
            });
        }
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AuthProvider(LoginPage));
