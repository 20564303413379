
import { SHOW_NOTIFICATION, CLOSE_NOTIFICATION} from './constants'

const initialState = {
    notificationMessage: '',
    showNotification: false,
    intent: 'primary'

};






const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_NOTIFICATION:
            return {
                ...state,
                notificationMessage: action.message,
                showNotification: true,
                intent: action.intent ? action.intent : 'primary'
            };

        case CLOSE_NOTIFICATION:
            return {
                ...state,
                notificationMessage: '',
                showNotification: false,
                intent: 'primary'
            }

        default:
            return {
                ...state
            };
    }
}

export default notificationReducer;