import {
    UPDATE_NAVIGATION,
    SHOW_DATETIME_PICKER,
    UPDATE_DATETIME
} from './constants';


export const updateNavigationAction = (data) => {
    return {
        type: UPDATE_NAVIGATION,
        data: data
    };
};

export const showDateTimePicker = (data) => {
    return {
        type: SHOW_DATETIME_PICKER,
        data: data
    };
};

export const updateDateTime = (start, end) => {
    return {
        type: UPDATE_DATETIME,
        start,
        end
    };
};