import React from 'react';
import { connect } from 'react-redux';
import { Grid, IconButton, Slider } from '@material-ui/core';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import Plotly from 'plotly.js-dist-min';
import createPlotlyComponent from 'react-plotly.js/factory';
import moment from 'moment';
import Loader from 'components/Loader';
import { SHOW_NOTIFICATION } from 'reducers/notification/constants';
// import WaterfallChart from './waterFallChart'

const Plot = createPlotlyComponent(Plotly);

const FFTTypes = ['Peak', 'RMS'];

class FFTSpectrum extends React.Component {

    render() {

        let scaleYData = []
        let scaleYDataReference = new Array(this.props.fftData.xData.length).fill(0);


        if (this.props.FFTType === FFTTypes[0]) {

            scaleYData = this.props.fftData.yData?.map((element) => {
                return Math.sqrt(element) * 1000
            })            

            if (this.props.fftData.yDataReference) {
                scaleYDataReference = this.props.fftData.yDataReference?.map((element) => {
                    return Math.sqrt(element) * 1000
                })
            }
        }
        else {
            scaleYData = this.props.fftData.yData?.map((element) => {
                return Math.sqrt(element) * 707.106781186547524
            })

            if (this.props.fftData.yDataReference) {
                scaleYDataReference = this.props.fftData.yDataReference?.map((element) => {
                    return Math.sqrt(element) * 707.106781186547524
                })
            }
        }

            

        return <Grid item style={{ textAlign: 'center' }}>
            <div id={"FFT"}>
                <div style={{ marginTop: '40px' }}>
                    {
                        (this.props.loadingTimestamps) ?
                            <span className={'slider-text'}> Loading </span> :
                            <span className={'slider-text'}>
                                Dataset:  {this.props.fftValue + 1} /
                                {this.props.timestamps.length + 1} -
                                {moment(this.props.timestamps[this.props.fftValue]).format('DD.MM.YYYY hh:mm:ss A')}
                            </span>
                    }
                    <Grid container justifyContent='space-between' style={{ textAlign: 'center' }}>
                        <Grid item style={{ marginLeft: (this.props.optimumWidth * 2 / 100) + 'px' }}>
                            <IconButton id='LindeButton' onClick={() => this.props.decreaseSliderValue()}><NavigateBefore /></IconButton>
                        </Grid>
                        <Grid item>
                            <Slider
                                id={'FFT-' + this.props.fftValue}
                                key={'FFT-' + this.props.fftValue}
                                defaultValue={this.props.fftValue}
                                onChangeCommitted={ this.props.trendOnChange}
                                valueLabelDisplay='auto'
                                step={1}
                                min={1}
                                max={this.props.timestamps.length}
                                marks={false}
                                style={{ width: (this.props.optimumWidth * 80 / 100) + 'px', color: '#005591' }}
                            />
                        </Grid>
                        <Grid item style={{ marginRight: (this.props.optimumWidth * 2 / 100) + 'px' }}>
                            <IconButton id='LindeButton' onClick={() => this.props.increaseSliderValue()}><NavigateNext /></IconButton>
                        </Grid>
                    </Grid>
                </div>

                <div style={{ height: '605px', position: 'relative'}}>
                <Plot useResizeHandler
                    data={[
                        {
                            x: this.props.fftData.xData,
                            y: scaleYDataReference, //Dont put reference until backend ready 
                            type: 'scatter',
                            name: this.props.fftData.yDataRefDate?.format('DD.MM.YYYY HH:mm:ss'),
                            mode: 'lines+points',
                            fill: 'tozeroy',
                            marker: { color: '#00A0E1' },
                        },
                        {
                            x: this.props.fftData.xData,
                            y: scaleYData,
                            type: 'scatter',
                            name: 'Selected',
                            mode: 'lines+points',
                            marker: { color: '#005591' },
                        }
                    ]}
                    layout={{
                        datarevision: this.props.fftDataRevision,
                        uirevision: this.props.fftDataRevision,
                        width: this.props.optimumWidth,
                        height: 600,
                        xaxis: {
                            range: [0, 1250],
                            type: 'string',
                            title: 'f in Hz',
                            color: '#005591'
                        },
                        yaxis: {
                            range: [0, 10],
                            type: 'string',
                            title: 'mm/s (' + this.props.FFTType + ')',
                            color: '#005591'
                        }
                    }}
                />
                {
                    (this.props.loadingSpectrum) ?
                        <Loader title='Generating Data' /> : 
                        <></>
                }
                </div>
            </div>
        </Grid>
    }
}



let mapStateToProps = (state) => state;

let mapDispatchToProps = dispatch => {
    return {
        showNotification: function (msg, intent) {
            dispatch({
                type: SHOW_NOTIFICATION,
                message: msg,
                intent: intent
            });
        }
    };
};



export default connect(mapStateToProps, mapDispatchToProps)(FFTSpectrum);
